import { APIResponse } from '@hedgit/lib/types/api'
import { ThunkAction } from '@hedgit/lib/types/redux-thunk'

import { get } from '@hedgit/lib/utils/axios'

import { PlanType } from '@hedgit/lib/interfaces/plan-type'

import {
  getPlanTypesPending,
  getPlanTypesFulfilled,
  getPlanTypesFailed
} from './actions'
import { PlanTypesActionTypes } from './types'

interface Response {
  planTypes: PlanType[];
}

export const getPlanTypes = (): ThunkAction<void, unknown, unknown, PlanTypesActionTypes> => {
  return async (dispatch) => {
    dispatch(getPlanTypesPending())
    try {
      const response = await get<APIResponse<Response>>('/plan-types/broker')
      dispatch(getPlanTypesFulfilled(response.data.planTypes))
    } catch (error) {
      dispatch(getPlanTypesFailed(error as string))
    }
  }
}
