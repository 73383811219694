import React, { useCallback, useEffect, useState } from 'react'
import { useForm, Controller, FieldValues } from 'react-hook-form'
import { View, Pressable, ActivityIndicator } from 'react-native'
import { yupResolver } from '@hookform/resolvers/yup'
import { Select, FormControl, ScrollView } from 'native-base'
import { useTranslation } from 'react-i18next'
import { theme } from '@hedgit/lib/theme'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { APIResponse } from '@hedgit/lib/types/api'
import { useToast } from 'react-native-toast-notifications'
import { useThunkDispatch } from '@hedgit/admin/src/store'
import { getDate } from '@hedgit/lib/translations/getTranslation'

import { createBroker, updateBroker } from '@hedgit/lib/store/modules/auth'
import { getBrokers } from '@hedgit/lib/store/modules/brokers/thunks'
import { getAreaCodesByBroker } from '@hedgit/lib/store/modules/area-codes/thunks'

import {
  CreateBrokerSchema,
  UpdateUserSchema,
  BillingInformationSchema,
  merge
} from '@hedgit/lib/utils/validations/user'
import { post } from '@hedgit/lib/utils/axios'
import { phoneNumberRegex } from '@hedgit/lib/utils/regex'

import { UserRole } from '@hedgit/lib/enums/user-role'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'
import { BrokerWithSubscriptionPlan } from '@hedgit/lib/interfaces/broker'
import { BillingInformation } from '@hedgit/lib/interfaces/billing-information'
import { User } from '@hedgit/lib/interfaces/user'
import { AreaCode } from '@hedgit/lib/interfaces/area-code'

import { AreaCodeCard } from '@hedgit/lib/components/cards/area-code-card'
import Body from '@hedgit/lib/components/typography/body'
import H1 from '@hedgit/lib/components/typography/h1'
import Title from '@hedgit/lib/components/typography/title'
import { SharedInput } from '@hedgit/lib/components/shared/input'
import { SharedButton } from '@hedgit/lib/components/shared/button'
import ErrorIcon from '@hedgit/lib/components/icons/error'
import PencilIcon from '@hedgit/lib/components/icons/pencil'
import GoBackIcon from '@hedgit/lib/components/icons/go-back'
import { MultiplePhoneNumbersInput } from '@hedgit/lib/components/shared/multiple-phone-numbers-input'
import UpgradeIcon from '@hedgit/lib/components/icons/upgrade'

import { useSelector } from 'store'

import { useFormFields } from './hooks'
import {
  Container,
  InputsContainer,
  ButtonContainer,
  Error,
  FieldContainer,
  HeaderContent,
  Content,
  SubContent,
  UpgradeButton,
  InputContainer,
  CardContent,
  AreaCodeContent,
  AreaCodeTitle,
  PlanInformation,
  EmptyAreaCodes
} from './styled'

type Props = NativeStackScreenProps<RootStackParamList, 'BrokersForm'>

const { colors } = theme
interface VerifyUserResponse {
  userExists: boolean;
}

enum ToastType {
  NORMAL = 'normal',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  CUSTOM = 'custom'
}

interface VerifyPhoneNumbersResponse {
  alreadyUsedPhoneNumbers: {
    [phone: string]: string;
  };
}

const validatePhoneNumbers = (values: string[]) =>
  values.every(v => v.length) &&
  values.every(v => phoneNumberRegex.test(v))

const BrokersForm = ({ route, navigation }: Props) => {
  const { t } = useTranslation()
  const dispatch = useThunkDispatch()
  const toast = useToast()

  const broker = route.params?.broker

  const isLoading = useSelector(store => store.auth.isFetching)

  const areaCodeLoading = useSelector(store => store.areaCodes.isFetching)

  const planTypes = useSelector(store => store.planType.list)

  const [error, setError] = useState('')

  const [areaCodes, setAreaCodes] = useState<AreaCode[]>([])

  const [disableRoleSelector, setDisableRoleSelector] = useState(false)
  const userPhones = (broker
    ? [broker.phone, ...broker.secondaryPhones || []].filter(p => p).map((p) => p.replace('+549', ''))
    : [''])

  const [phoneNumbers, setPhoneNumbers] = useState(userPhones)
  const [phoneErrors, setPhoneErrors] = useState<Record<number, string>>({})

  const validPhoneNumbers = validatePhoneNumbers(phoneNumbers)

  const { userFields, billingInformationFields } = useFormFields()

  const {
    control,
    reset,
    setValue,
    handleSubmit: handleSubmitForm,
    formState: { errors, isSubmitting },
    clearErrors
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: broker
      ? yupResolver(merge(UpdateUserSchema, BillingInformationSchema))
      : yupResolver(merge(CreateBrokerSchema, BillingInformationSchema))
  })

  useEffect(() => {
    if (!isSubmitting) return
    phoneNumbers.forEach((phone) => {
      if (phone) return
      const newErrors = {
        ...errors,
        [phone]: t('ErrorMessages.phone.required')
      }
      setPhoneErrors(newErrors)
    })
  }, [errors, isSubmitting, phoneNumbers, t])

  const showToast = (type: ToastType, message: string) => {
    toast.show(message, {
      type: type,
      duration: 4000,
      placement: 'bottom',
      animationType: 'zoom-in'
    })
  }

  const handleSubmit = async (data: FieldValues) => {
    const { country, state, city, postalCode, address, ...rest } = data

    data = {
      ...rest,
      billingInformation: {
        country: country,
        state: state,
        city: city,
        postalCode: postalCode,
        address: address
      }
    }

    try {
      clearErrors()
      if (validPhoneNumbers) {
        const phone = `+549${phoneNumbers[0]}`

        let secondaryPhones: string[] = []

        if (phoneNumbers.length > 1) {
          secondaryPhones = phoneNumbers.slice(1).map(p => `+549${p}`)
        }

        const {
          data: { alreadyUsedPhoneNumbers }
        } = await post<APIResponse<VerifyPhoneNumbersResponse>>(
          '/auth/verify-phone-numbers',
          { phones: [phone, ...secondaryPhones || []], userId: broker?.id }
        )

        if (Object.values(alreadyUsedPhoneNumbers).length) {
          const newErrors = Object.keys(alreadyUsedPhoneNumbers).reduce((accum, phone) => {
            const p = phone.replace('+549', '')
            accum[p.toString()] = t('Settings.alreadyUsedPhoneNumber')
            return accum
          }, {} as Record<string, string>)
          setPhoneErrors((prevErrors) => ({
            ...prevErrors,
            ...newErrors
          }))
        } else {
          if (broker) {
            await (dispatch(updateBroker({
              ...data,
              role: data.role || broker.role,
              id: broker.id,
              phone,
              secondaryPhones
            } as BrokerWithSubscriptionPlan))).then(async (res) => {
              if (res) {
                return showToast(ToastType.DANGER, res)
              }
              showToast(ToastType.SUCCESS, 'Partner actualizado con éxito')
              await dispatch(getBrokers())
              navigation.navigate('BrokersTable')
            })
          } else {
            const response = await post<APIResponse<VerifyUserResponse>>('/auth/verify', { phone: data.phone })

            if (!response.data.userExists) {
              await (dispatch(createBroker({
                ...data,
                phone,
                secondaryPhones
              } as User))).then(async (res) => {
                if (res) {
                  return showToast(ToastType.DANGER, res)
                }
                showToast(ToastType.SUCCESS, 'Partner creado con éxito')
                await dispatch(getBrokers())
                navigation.navigate('BrokersTable')
              })
            } else {
              setError('El número de teléfono ya pertenece a un usuario registrado.')
              showToast(ToastType.DANGER, 'El número de teléfono ya pertenece a un usuario registrado.')
            }
          }
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getAreaCodes = useCallback(async () => {
    if (!broker) return setAreaCodes([])

    const response = await dispatch(getAreaCodesByBroker(broker.id))

    if (response.success) {
      return setAreaCodes(response.areaCodes ?? [])
    }

    return setAreaCodes([])
  }, [broker, dispatch])

  useEffect(() => {
    getAreaCodes()
  }, [getAreaCodes])

  useEffect(() => {
    if (broker) {
      broker.subscriptionPlan && setDisableRoleSelector(true)
      const user = userFields.reduce((accum, field) => {
        accum[field.name] = broker[field.name]
        return accum
      }, {} as BrokerWithSubscriptionPlan)

      reset({ ...user, planType: broker.subscriptionPlan?.planType.id })

      billingInformationFields.forEach(field => {
        const fieldName = field.name.split('.')[1] as keyof BillingInformation
        if (broker.billingInformation) {
          setValue(fieldName, broker.billingInformation?.[fieldName])
        }
      })
    }
  }, [broker, reset, billingInformationFields, userFields, setValue])

  return (
    <Content>
      <SubContent>
        <HeaderContent>
          <Pressable testID='nav-bar-menu' onPress={() => navigation.goBack()}>
            <GoBackIcon color={colors.dark} />
          </Pressable>
          <H1>{broker ? 'Editar partner' : 'Agregar partner'}</H1>
          <View />
        </HeaderContent>
        <Container>
          <InputsContainer>
            <View style={{ flex: 1 }}>
              <Title style={{ marginBottom: 32 }}>
                {t('Brokers.form.personalInformation')}
              </Title>
              {userFields.map((field, index) => (
                <FieldContainer
                  key={field.name}
                  style={index === userFields.length - 1 && { marginBottom: 0 }}
                >
                  <FormControl isInvalid={field.name in errors}>
                    <Controller
                      name={field.name}
                      control={control}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <SharedInput
                          testID={field.testID}
                          withLabel
                          label={field.label}
                          onChange={onChange}
                          onFocus={() => {
                            clearErrors(field.name)
                            setError('')
                          }}
                          onBlur={onBlur}
                          value={value}
                          keyboardType={field.keyboardType || 'default'}
                          placeholder={field.placeholder}
                          endAdornment={<PencilIcon width={24} height={24} color='#adb1b8' />}
                        />
                      )}
                    />
                    <FormControl.ErrorMessage
                      testID="su-farmer-firstName-error-message"
                      leftIcon={<ErrorIcon width={14} height={14} color={colors.red} />}
                      _text={{
                        style: {
                          color: colors.red,
                          fontSize: 12,
                          fontFamily: 'Lato'
                        }
                      }}
                    >
                      {errors[field.name]?.message}
                    </FormControl.ErrorMessage>
                  </FormControl>
                </FieldContainer>
              ))}
              <Body style={{ marginBottom: 24 }}>
                Ingresá cód. área y núm. sin 0 y 15 (Ej. 3415111000). Sin código de país.
              </Body>
              <MultiplePhoneNumbersInput
                values={phoneNumbers}
                setValues={setPhoneNumbers}
                errors={phoneErrors}
                setErrors={setPhoneErrors}
              />
              <FieldContainer>
                <FormControl isInvalid={'role' in errors}>
                  <Controller
                    name='role'
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Select
                        testID='als-selectRole-select'
                        placeholder={t('Brokers.form.role.placeholder')}
                        selectedValue={value}
                        onValueChange={onChange}
                        defaultValue={broker?.role}
                        onClose={() => {
                          setError('')
                          clearErrors('role')
                        }}
                        width='100%'
                        h={52}
                        paddingRight={18}
                        paddingLeft={18}
                        borderColor={colors.gray}
                        borderRadius={10}
                        fontSize={14}
                      >
                        <Select.Item
                          label={t('Brokers.form.role.broker')}
                          value={UserRole.broker}
                          key='select-broker'
                        />
                        <Select.Item
                          label={t('Brokers.form.role.broker_farmer')}
                          value={UserRole.broker_farmer}
                          key='select-broker-farmer'
                        />
                      </Select>
                    )}
                  />
                  <FormControl.ErrorMessage
                    testID="su-farmer-firstName-error-message"
                    leftIcon={<ErrorIcon width={14} height={14} color={colors.red} />}
                    _text={{
                      style: {
                        color: colors.red,
                        fontSize: 12,
                        fontFamily: 'Lato'
                      }
                    }}
                  >
                    {errors.role?.message}
                  </FormControl.ErrorMessage>
                </FormControl>
              </FieldContainer>
              <FieldContainer>
                <FormControl isInvalid={'planType' in errors}>
                  <Controller
                    name="planType"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <View>
                        <InputContainer>
                          <View style={{ flex: 1 }}>

                            <Select
                              testID='als-selectPlan-select'
                              placeholder={t('Brokers.form.planType.placeholder')}
                              selectedValue={value}
                              defaultValue={undefined}
                              onValueChange={onChange}
                              onClose={() => {
                                setError('')
                                clearErrors('planType')
                              }}
                              width='100%'
                              h={52}
                              paddingRight={18}
                              paddingLeft={18}
                              borderColor={colors.gray}
                              borderRadius={10}
                              fontSize={14}
                              isDisabled={disableRoleSelector}
                            >
                              {planTypes.map(plan => (
                                <Select.Item
                                  label={plan.name}
                                  value={plan.id}
                                  key={plan.id}
                                />
                              ))}
                            </Select>
                          </View>
                          {broker?.subscriptionPlan &&
                          (
                            <UpgradeButton
                              onPress={() => setDisableRoleSelector(false)}
                            >
                              <UpgradeIcon
                                style={{
                                  width: 36,
                                  height: 36,
                                  marginLeft: 12,
                                  opacity: 0.8
                                }}
                                color={theme.colors.green}
                              />
                            </UpgradeButton>
                          )}
                        </InputContainer>
                        {broker?.subscriptionPlan && (
                          <PlanInformation>
                            {`Plan activo: ${broker?.subscriptionPlan?.planType.name}`}
                          </PlanInformation>
                        )}
                        {broker?.subscriptionPlan?.expirationDate && (
                          <PlanInformation>
                            {`Fecha de expiración: ${getDate(new Date(broker.subscriptionPlan.expirationDate))}`}
                          </PlanInformation>
                        )}
                      </View>
                    )}
                  />
                </FormControl>
              </FieldContainer>
            </View>
            <View style={{ flex: 1 }}>
              <Title style={{ marginBottom: 32 }}>
                {t('Settings.billingInformationLabel')}
              </Title>
              {billingInformationFields.map((field, index) => (
                <FieldContainer
                  key={field.name}
                  style={
                  index === billingInformationFields.length - 1 && {
                    marginBottom: 0
                  }
                  }
                >
                  <FormControl isInvalid={field.name.split('.')[1] in errors}>
                    <Controller
                      name={field.name.split('.')[1]}
                      control={control}
                      defaultValue=""
                      render={({ field: { value, onChange, onBlur } }) => (
                        <SharedInput
                          testID={field.testID}
                          withLabel
                          label={field.label}
                          value={value}
                          onChange={onChange}
                          onFocus={() => {
                            setError('')
                            clearErrors(field.name.split('.')[1])
                          }}
                          onBlur={onBlur}
                          keyboardType={field.keyboardType || 'default'}
                          placeholder={field.placeholder}
                          endAdornment={<PencilIcon width={24} height={24} color='#adb1b8' />}
                        />
                      )}
                    />
                    <FormControl.ErrorMessage
                      testID={field.testID + '-error'}
                      leftIcon={<ErrorIcon width={14} height={14} color={colors.red} />}
                      _text={{
                        style: {
                          color: colors.red,
                          fontSize: 12,
                          fontFamily: 'Lato'
                        }
                      }}
                    >
                      {errors[field.name.split('.')[1]]?.message}
                    </FormControl.ErrorMessage>
                  </FormControl>
                </FieldContainer>
              ))}
              <View>
                <AreaCodeTitle>{t('Components.card.brokerAreaCodes.title')}</AreaCodeTitle>
                <CardContent>
                  <ScrollView>
                    { areaCodeLoading
                      ? (<ActivityIndicator color={colors.primary} style={{ flex: 1 }} />)
                      : (areaCodes.length > 0)
                          ? (
                            <AreaCodeContent>
                              {areaCodes.map((code) => (
                                <AreaCodeCard key={code.prefix.toString()} value={code.prefix.toString()} />
                              ))}
                            </AreaCodeContent>
                            )
                          : <EmptyAreaCodes>{t('Components.card.brokerAreaCodes.empty')}</EmptyAreaCodes>}
                  </ScrollView>
                </CardContent>
              </View>
            </View>
          </InputsContainer>
          <Error testID="set-error-messages">{error}</Error>
          <ButtonContainer>
            <SharedButton
              testID="set-submit-button"
              variant="primary"
              onPress={handleSubmitForm(handleSubmit)}
              disabled={isLoading}
              loading={isLoading}
              style={{
                width: 300
              }}
            >
              {t('Settings.submitButton')}
            </SharedButton>
          </ButtonContainer>
        </Container>
        <View style={{ height: 16 }} />
      </SubContent>
    </Content>
  )
}

export default BrokersForm
