import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { theme } from '@hedgit/lib/theme'
import { Pressable } from 'native-base'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'

import GoBackIcon from '@hedgit/lib/components/icons/go-back'

type NavigationType = NativeStackNavigationProp<RootStackParamList, 'Brokers'>

const HeaderLeftButton = () => {
  const navigation = useNavigation<NavigationType>()

  return (
    <Pressable testID='nav-bar-go-back' onPress={() => navigation.goBack()} style={{ padding: 16 }}>
      <GoBackIcon color={theme.colors.dark} />
    </Pressable>
  )
}

export default HeaderLeftButton
