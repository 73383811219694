import React from 'react'
import { Modal as RNModal, GestureResponderEvent } from 'react-native'
import { useTranslation } from 'react-i18next'

import {
  CenteredView,
  ModalView,
  ButtonContainer,
  ButtonsContainer,
  Button
} from './styled'

import Title from '../../typography/title'
import Italic from '../../typography/italic'

interface IButton {
  label: string;
  onPress: (event: GestureResponderEvent) => void;
  testID: string;
  variant: 'primary' | 'secondary';
  disabled?: boolean;
}

export interface ModalProps {
  buttons?: IButton[];
  visible?: boolean;
  onCloseModal?: () => void;
  areaCodes: number[];
  broker?: string;
}

const DeleteAreaCodesModal = ({
  buttons,
  visible,
  areaCodes,
  broker,
  onCloseModal
}: ModalProps) => {
  const { t } = useTranslation()
  return (
    <RNModal
      animationType='fade'
      visible={visible}
      presentationStyle='overFullScreen'
      statusBarTranslucent={false}
      transparent
      onRequestClose={onCloseModal}
    >
      <CenteredView testID="delete-modal">
        <ModalView>
          <Title>
            ¿
            {areaCodes.length > 1
              ? t('Components.modal.delete.firstPluralPart')
              : t('Components.modal.delete.firstPart') }
            <Italic>
              {areaCodes.map(areaCode => `(+${areaCode}) `)}
            </Italic>
            {t('Components.modal.delete.secondPart')}
            {broker}
            ?
          </Title>
          <ButtonsContainer>
            {buttons && buttons.map((button) => (
              <ButtonContainer key={button.label}>
                <Button
                  testID={button.testID}
                  variant={button.variant}
                  onPress={button.onPress}
                  disabled={button.disabled}
                >
                  {button.label}
                </Button>
              </ButtonContainer>
            ))}
          </ButtonsContainer>
        </ModalView>
      </CenteredView>
    </RNModal>
  )
}

export default DeleteAreaCodesModal
