export enum PlanName {
    xpExperimental = 'XP Experimental',
    xpProfessional = 'XP Profesional',
    xpOriginator = 'XP Originador',
    xpPremium = 'Premium XP',
    xpExperimentalPlus = 'XP Experimental +',
    xpProfessionalPlus = 'XP Profesional +',
    xpOriginatorPlus = 'XP Originador +',
    proTrader = 'Pro Trader',
    premiumTrader = 'Premium Trader',
}
