import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HStack, View, Image } from 'native-base'
import { TouchableOpacity } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { theme } from '@hedgit/lib/theme'
import { useNavigation, useRoute } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { useDispatch } from '@hedgit/admin/src/store'

import { signOutThunk } from '@hedgit/lib/store/modules/auth'

import { get } from '@hedgit/lib/utils/axios'
import { download } from '@hedgit/lib/utils/download'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'

import H2 from '@hedgit/lib/components/typography/h2'
import ButtonText from '@hedgit/lib/components/typography/button-text'
import { Modal } from '@hedgit/lib/components/modals/basic-modal'

import {
  Content,
  SubContentRight,
  SubContentLeft,
  StyledDivider,
  TitleText,
  AdminContent,
  TitleUnderline,
  DownloadButton,
  Pressable
} from './styled'

type NavigationType = NativeStackNavigationProp<RootStackParamList, 'BrokersTable'>

const { colors } = theme

const NavBar = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)
  const [resource, setResource] = useState({
    isUsersCsvDownloading: false,
    isSignalsCsvDownloading: false,
    isSubscriptionsCsvDownloading: false
  })

  const tableTitles = [
    {
      title: 'Partners',
      screen: 'Brokers',
      order: 1
    },
    {
      title: 'Market Data',
      screen: 'MarketData',
      order: 2
    },
    {
      title: t('NavigationTabs.areaCodes'),
      screen: 'AreaCodes',
      order: 3
    },
    {
      title: 'Señales',
      screen: 'Signals',
      order: 4
    }
  ]

  const navigation = useNavigation<NavigationType>()
  const route = useRoute()

  const [selectedScreen, setSelectedScreen] = useState('BrokersTable')

  useEffect(() => {
    const currentRoute = route.name.replace('Screen', '')
    setSelectedScreen(currentRoute)
  }, [navigation, route.name])

  const getUsers = async () => {
    try {
      setResource((prev) => ({ ...prev, isUsersCsvDownloading: true }))
      const response = await get<string>('/users/users-to-csv')
      download('users.csv', response)
    } catch (error) {
      console.error(error)
    } finally {
      setResource((prev) => ({ ...prev, isUsersCsvDownloading: false }))
    }
  }

  const getPricingSignalsCsv = async () => {
    try {
      setResource((prev) => ({ ...prev, isSignalsCsvDownloading: true }))
      const response = await get<string>('/pricing-signals/signals-to-csv')
      download('signals.csv', response)
    } catch (error) {
      console.error(error)
    } finally {
      setResource((prev) => ({ ...prev, isSignalsCsvDownloading: false }))
    }
  }

  const getSubscriptionsCsv = async () => {
    try {
      setResource((prev) => ({ ...prev, isSubscriptionsCsvDownloading: true }))
      const response = await get<string>('/subscriptions/subscriptions-to-csv')
      download('subscriptions.csv', response)
    } catch (error) {
      console.error(error)
    } finally {
      setResource((prev) => ({ ...prev, isSubscriptionsCsvDownloading: false }))
    }
  }

  const signOutUser = useCallback(async () => {
    AsyncStorage.removeItem('auth_token')
    dispatch(signOutThunk())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Content>
      <SubContentLeft>
        <HStack alignItems="center">
          <Image
            style={{ width: 120, height: 30 }}
            source={require('@hedgit/lib/assets/images/hedgit-icon.png')}
            alt="hedgitLogo"
          />
          <AdminContent>
            Admin
          </AdminContent>
          <StyledDivider style={{ marginTop: '1%', transform: [{ rotate: '90deg' }], right: '5.5%', width: '50px' }} />
        </HStack>
        {tableTitles.map((title) => (
          <TouchableOpacity
            key={title.order}
            style={{
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginRight: 46
            }}
            onPress={() => {
              navigation.navigate((title.screen) as keyof RootStackParamList)
            }}
          >
            <TitleText selected={title.screen === selectedScreen}>{title.title}</TitleText>
            <TitleUnderline bgColor={title.screen === selectedScreen ? colors.primary : colors.white} />
          </TouchableOpacity>
        ))}
      </SubContentLeft>
      <SubContentRight>
        <View style={{
          marginRight: 32,
          flexDirection: 'row'
        }}
        >
          <DownloadButton disabled={resource.isUsersCsvDownloading} onPress={getUsers}>
            <ButtonText style={{ color: colors.primary }}>
              {t(resource.isUsersCsvDownloading ? 'Common.loading' : 'Admin.navbar.downloadUsers')}
            </ButtonText>
          </DownloadButton>
          <DownloadButton disabled={resource.isSignalsCsvDownloading} onPress={getPricingSignalsCsv}>
            <ButtonText style={{ color: colors.primary }}>
              {t(resource.isSignalsCsvDownloading ? 'Common.loading' : 'Admin.navbar.downloadPricingSignals')}
            </ButtonText>
          </DownloadButton>
          <DownloadButton disabled={resource.isSubscriptionsCsvDownloading} onPress={getSubscriptionsCsv}>
            <ButtonText style={{ color: colors.primary }}>
              {t(resource.isSubscriptionsCsvDownloading ? 'Common.loading' : 'Admin.navbar.downloadSubscriptions')}
            </ButtonText>
          </DownloadButton>
        </View>
        <Pressable onPress={() => setShowModal(true)}>
          <H2>Log out</H2>
        </Pressable>
      </SubContentRight>
      <Modal
        title={t('Components.modal.logOut.title')}
        variant="confirm"
        visible={showModal}
        buttons={[
          {
            label: t('Components.modal.button.stay'),
            onPress: () => setShowModal(false),
            testID: 'stay-modal-button',
            variant: 'secondary'
          },
          {
            label: t('Components.modal.button.confirm'),
            onPress: signOutUser,
            testID: 'log-out-modal-button',
            variant: 'primary'
          }
        ]}
      />
    </Content>
  )
}

export default NavBar
