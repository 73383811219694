import React, { useEffect, useMemo, useState } from 'react'
import { ActivityIndicator } from 'react-native'
import { APIResponse } from '@hedgit/lib/types/api'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  SortingState,
  SortingFn
} from '@tanstack/react-table'

import { get } from '@hedgit/lib/utils/axios'

import { Notification } from '@hedgit/lib/interfaces/notifications'
import { User } from '@hedgit/lib/interfaces/user'

import {
  TableContent,
  Thead,
  Tr,
  Th,
  Td,
  TextNotFound,
  SignalsTable,
  TablePaginationWrapper,
  Content
} from './styled'
import { clickableHeader } from './constants'

const Signals = () => {
  const [notifications, setNotifications] = useState<Notification[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [sorting, setSorting] = useState<SortingState>([])

  useEffect(() => {
    const url = '/notifications/signalsCreated'
    get<APIResponse<{ notifications: Notification[] }>>(url)
      .then(response => {
        setNotifications(response.data.notifications)
        setIsLoading(false)
      })
      .catch(error => {
        console.error(error)
        setIsLoading(false)
      })
  }, [])

  const sortUserFn: SortingFn<Notification> = (rowA, rowB) => {
    return rowA.original.user.firstName < rowB.original.user.firstName ? 1 : -1
  }

  const columns = useMemo<ColumnDef<Notification>[]>(() => [
    {
      accessorKey: 'user',
      header: 'Usuario',
      cell: cell => {
        const userData = cell.getValue() as User
        return `${userData.firstName} ${userData.lastName}`
      },
      sortingFn: sortUserFn
    },
    {
      accessorKey: 'time',
      header: 'Fecha',
      cell: cell => {
        const date = cell.getValue() as string
        return date.split('T')[0]
      },
      sortDescFirst: true
    },
    {
      accessorKey: 'user.role',
      header: 'Rol',
      enableSorting: false
    },
    {
      accessorKey: 'user.phone',
      header: 'Teléfono',
      enableSorting: false
    },
    {
      accessorKey: 'user.secondaryPhones',
      header: 'Teléfonos Secundarios',
      cell: info => {
        const secondaryPhones = info.getValue() as string[]
        return secondaryPhones.join(',\n')
      },
      enableSorting: false
    },
    {
      accessorKey: 'message',
      header: 'Mensaje',
      enableSorting: false
    },
    {
      accessorKey: 'isRead',
      header: 'Leído',
      cell: info => info.getValue() ? 'Sí' : 'No',
      enableSorting: false
    }
  ], [])

  const table = useReactTable({
    data: notifications,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting
    },
    onSortingChange: setSorting
  })

  if (isLoading) return <ActivityIndicator size="large" color="#0000ff" />

  return (
    <Content>
      {table.getRowModel().rows.length === 0
        ? (
          <TextNotFound>No se encontraron Señales.</TextNotFound>
          )
        : (
          <TablePaginationWrapper>
            <TableContent>
              <SignalsTable>
                <Thead>
                  {table.getHeaderGroups().map(headerGroup => (
                    <Tr key={headerGroup.id}>
                      {headerGroup.headers.map(header => {
                        return (
                          <Th key={header.id} colSpan={header.colSpan}>
                            <div
                              style={
                                header.column.getCanSort()
                                  ? clickableHeader
                                  : {}
                              }
                              onClick={header.column.getToggleSortingHandler()}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {{
                                asc: ' ↑',
                                desc: ' ↓'
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                          </Th>
                        )
                      })}
                    </Tr>
                  ))}
                </Thead>
                <tbody>
                  {table.getRowModel().rows.map(row => (
                    <Tr key={row.id}>
                      {row.getVisibleCells().map(cell => (
                        <Td key={cell.id} style={{ width: cell.column.getSize() }}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Td>
                      ))}
                    </Tr>
                  ))}
                </tbody>
              </SignalsTable>
            </TableContent>
          </TablePaginationWrapper>
          )}
    </Content>
  )
}

export default Signals
