import styled from 'styled-components/native'

export const AddButton = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`

export const DeleteButton = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const InputContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
export const ContainerError = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
`
