import React from 'react'
import { Input, InputGroup } from 'native-base'
import TextInputProps from 'react-native'

import { Container, EndAdornmentContainer, LeftAddon, RightAddon } from './styled'

import { theme } from '../../../theme'
import Body from '../../typography/body'

import './styles.css'

export type InputProps = {
  testID?: string;
  value?: string;
  required?: boolean;
  disabled?: boolean;
  withLabel: boolean;
  label?: string;
  placeholder?: string;
  keyboardType: TextInputProps.KeyboardTypeOptions;
  maxLength?: number;
  onChange?: (e: TextInputProps.NativeSyntheticEvent<TextInputProps.TextInputChangeEventData>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  endAdornment?: React.ReactNode;
  defaultValue?: string;
  leftComponent?: React.ReactNode;
  rightComponent?: React.ReactNode;
}

const colors = theme.colors

const SharedInput: React.FC<InputProps> = ({
  testID,
  value,
  required,
  disabled,
  withLabel,
  label,
  placeholder,
  keyboardType,
  maxLength,
  onChange,
  onFocus,
  onBlur,
  endAdornment,
  defaultValue,
  leftComponent,
  rightComponent
}: InputProps) => {
  return (
    <Container>
      {withLabel
        ? (
          <Body
            style={{
              position: 'absolute',
              top: -12,
              left: 14,
              backgroundColor: colors.white,
              borderLeftWidth: 5,
              borderRightWidth: 5,
              borderColor: colors.white,
              zIndex: 1
            }}
          >
            {label}
          </Body>
          )
        : null}

      <InputGroup>
        <Input
          InputLeftElement={leftComponent && <LeftAddon borderRightWidth="1">{leftComponent}</LeftAddon>}
          InputRightElement={rightComponent && <RightAddon>{rightComponent}</RightAddon>}
          testID={testID}
          value={value}
          defaultValue={defaultValue}
          isRequired={required}
          isDisabled={disabled}
          placeholder={placeholder}
          keyboardType={keyboardType}
          maxLength={maxLength}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          color={colors.dark}
          backgroundColor='transparent'
          borderColor={colors.gray}
          placeholderTextColor={colors.gray}
          _focus={{
            borderColor: colors.dark,
            color: colors.dark
          }}
          _invalid={{
            borderColor: colors.red,
            color: colors.red
          }}
          h={52}
          fontSize={14}
          borderRadius={10}
          paddingRight={18}
          paddingLeft={18}
          width="100%"
        />
      </InputGroup>
      {
        endAdornment && (
        <EndAdornmentContainer>{endAdornment}</EndAdornmentContainer>
        )
}
    </Container>
  )
}

export default SharedInput
