import React, { useCallback, useEffect, useState } from 'react'
import {
  View,
  ListRenderItem,
  ListRenderItemInfo,
  TouchableOpacity,
  ActivityIndicator
} from 'react-native'
import { Select, FlatList, FormControl, InputGroup, WarningOutlineIcon } from 'native-base'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { theme } from '@hedgit/lib/theme'
import { APIResponse } from '@hedgit/lib/types/api'

import {
  editMarketData,
  setEditingMarketData,
  getMarketDataByProduct,
  createMarketDataPending,
  createMarketDataFailed,
  createMarketDataFulfilled
} from '@hedgit/lib/store/modules/market-data'
import { getProducts } from '@hedgit/lib/store/modules/products/thunks'

import { formatDate, formatNumber } from '@hedgit/lib/utils/format'
import { post } from '@hedgit/lib/utils/axios'

import { MarketData } from '@hedgit/lib/interfaces/market-data'
import { Product } from '@hedgit/lib/interfaces/product'

import H2 from '@hedgit/lib/components/typography/h2'
import Title from '@hedgit/lib/components/typography/title'
import Body from '@hedgit/lib/components/typography/body'
import { SharedInput } from '@hedgit/lib/components/shared/input'
import { SharedButton } from '@hedgit/lib/components/shared/button'
import { Modal } from '@hedgit/lib/components/modals/basic-modal'
import H3 from '@hedgit/lib/components/typography/h3'

import { useDispatch, useSelector } from 'store'

import { formValidationSchema } from 'utils/validations/market-data/index'

import {
  Content,
  SubContent,
  FormContent,
  TableContent,
  ButtonContainer,
  FieldContainer,
  ListContainer,
  Error,
  ProductTitle
} from './styled'

const SEPARATOR_CHAR = '-'

const { colors } = theme

const monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
  'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
]

const getProductLabel = (product: Product) => {
  return [
    product.crop.name.es,
    monthNames[product.month - 1],
    product.destination.name,
    product.year
  ].join(SEPARATOR_CHAR)
}

interface FormData {
  product: string;
  date: string;
  value: number;
}

interface CreateResponse {
  marketData: MarketData;
}

const isPriceOutOfRange = (price: number, referenceValue: number) => {
  const isOver = referenceValue + (referenceValue * 5 / 100)

  const isBelow = referenceValue - (referenceValue * 5 / 100)

  return price <= isBelow || price >= isOver
}

export const getDate = (dateP: Date | string) => {
  const date = new Date(dateP)
  if (!date) return ''
  date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
  return formatDate(date)
}

const MarketDataScreen = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [successModalVisible, setSuccessModalVisible] = useState<boolean>(false)
  const [priceConfirmationModalVisible, setPriceConfirmationModalVisible] = useState<boolean>(false)

  const editingMarketData = useSelector(state => state.marketData.editingMarketData)
  const marketDataByProduct = useSelector(state => state.marketData.fullListByProduct)
  const isLoading = useSelector(state => state.marketData.isFetching)
  const isLoadingActionMarketData = useSelector(state => state.marketData.isCreatingMarketData)
  const products = useSelector(state => state.products.list)

  const [error, setError] = useState<string>('')

  useEffect(() => {
    dispatch(getProducts())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    control,
    handleSubmit: handleSubmitForm,
    formState: { errors },
    clearErrors,
    reset,
    watch
  } = useForm<FormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      date: '',
      product: '',
      value: 0
    },
    resolver: yupResolver(formValidationSchema)
  })

  const selectedProduct = watch('product')

  const formReset = () => {
    reset({
      product: '',
      date: '',
      value: 0
    })
  }

  useEffect(() => {
    if (selectedProduct) {
      dispatch(getMarketDataByProduct(
        selectedProduct, { summary: false, sort: 'desc', limit: false }
      ))
    }
  }, [selectedProduct, dispatch])

  const marketData = marketDataByProduct[selectedProduct || ''] || []

  const renderItem: ListRenderItem<MarketData> = useCallback(
    (data: ListRenderItemInfo<MarketData>) => {
      return (
        <TouchableOpacity onPress={() => {
          dispatch(setEditingMarketData(data.item))
        }}
        >
          <View style={{
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            borderBottomColor: colors.primary,
            borderBottomWidth: 1
          }}
          >
            <Body>{getDate(data.item.date)}</Body>
            {/* <Body>{new Date(Date.parse(data.item.date as any))}</Body> */}
            <Body>
              U$D
              {' '}
              {data.item.value?.toFixed(2)}
            </Body>
          </View>
        </TouchableOpacity>
      )
    },
    [dispatch]
  )

  useEffect(() => {
    if (editingMarketData) {
      reset({
        date: getDate(new Date(editingMarketData.date)),
        product: editingMarketData.product,
        value: editingMarketData.value
      })
    } else {
      reset({
        product: selectedProduct
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingMarketData])

  const successAction = () => {
    setSuccessModalVisible(true)
    if (priceConfirmationModalVisible) setPriceConfirmationModalVisible(false)
    dispatch(getMarketDataByProduct(
      selectedProduct, { summary: false, sort: 'desc', limit: false }
    ))
  }

  const handleSubmit = async (formData: FormData) => {
    const lastPrice = marketData[0]?.value

    if (!lastPrice) {
      confirmMarketDataAction(formData)
      return
    }

    const isMarketPriceOutOfRange = isPriceOutOfRange(formData.value, lastPrice)

    if (isMarketPriceOutOfRange) {
      setPriceConfirmationModalVisible(true)
      return
    }

    confirmMarketDataAction(formData)
  }

  const confirmMarketDataAction = async (formData: FormData) => {
    if (editingMarketData) {
      const marketData = {
        id: editingMarketData._id,
        date: formData.date,
        product: formData.product,
        value: formData.value
      }
      dispatch(editMarketData(marketData))
      successAction()
    } else {
      dispatch(createMarketDataPending())
      try {
        const response = await post<APIResponse<CreateResponse>>(
          '/market-data',
          formData,
          {},
          0
        )
        dispatch(createMarketDataFulfilled(response.data.marketData))
        successAction()
      } catch (error) {
        dispatch(createMarketDataFailed(error as string))
        setError(t('MarketData.form.error'))
      }
    }
  }

  const closePriceConfirmationModal = () => {
    setPriceConfirmationModalVisible(false)
  }

  const closeSuccessModal = () => {
    setSuccessModalVisible(false)
  }

  return (
    <Content>
      <SubContent>
        <FormContent>
          <ProductTitle>
            {t('MarketData.titleProduct')}
          </ProductTitle>
          <FieldContainer>
            <FormControl isInvalid={'product' in errors}>
              <Controller
                name="product"
                control={control}
                rules={{
                  required: { value: true, message: t('MarketData.form.date.required') }
                }}
                render={({ field: { value, onChange } }) => (
                  <Select
                    testID="md-filter-select"
                    minWidth="200"
                    defaultValue=''
                    onValueChange={onChange}
                    selectedValue={value}
                    onClose={() => {
                      clearErrors('product')
                      setError('')
                    }}
                    width='100%'
                    h={52}
                    paddingRight={18}
                    paddingLeft={18}
                    borderColor={colors.gray}
                    backgroundColor={colors.white}
                    borderRadius={10}
                    fontSize={14}
                  >
                    <Select.Item label={t('MarketData.form.product.placeholder')} value='' key='default-select-value' />
                    {
                      products.map(item => <Select.Item label={getProductLabel(item)} value={item.id} key={item.id} />)
                    }
                  </Select>
                )}
              />
              <FormControl.ErrorMessage
                testID="md-dateError-message"
                leftIcon={<WarningOutlineIcon size="xs" />}
              >
                {errors.product?.message}
              </FormControl.ErrorMessage>
            </FormControl>
          </FieldContainer>
          <H2 style={{
            marginBottom: 16,
            marginLeft: 8
          }}
          >
            {t('MarketData.titlePrice')}
          </H2>
          <FieldContainer>
            <FormControl isInvalid={'date' in errors}>
              <Controller
                name="date"
                control={control}
                rules={{
                  required: { value: true, message: t('MarketData.form.date.required') }
                }}
                render={({ field: { onBlur, onChange, value } }) => (
                  <SharedInput
                    testID="md-date-input"
                    value={value}
                    required
                    withLabel={false}
                    label={t('MarketData.form.date.label')}
                    placeholder={t('MarketData.form.date.placeholder')}
                    keyboardType='default'
                    onChange={onChange}
                    onFocus={() => {
                      clearErrors('date')
                      setError('')
                    }}
                    onBlur={onBlur}
                  />
                )}
              />
              <FormControl.ErrorMessage
                testID="md-dateError-message"
                leftIcon={<WarningOutlineIcon size="xs" />}
              >
                {errors.date?.message}
              </FormControl.ErrorMessage>
            </FormControl>
          </FieldContainer>
          <FieldContainer>
            <FormControl isInvalid={'value' in errors}>
              <Controller
                name="value"
                control={control}
                rules={{
                  required: { value: true, message: t('MarketData.form.requirements.value') }
                }}
                render={({ field: { onBlur, onChange, value } }) => (
                  <InputGroup style={{
                    flexDirection: 'row'
                  }}
                  >
                    <SharedInput
                      rightComponent={t('MarketData.form.value.currency')}
                      testID="md-value-input"
                      value={formatNumber(value.toString())}
                      required
                      withLabel={false}
                      label={t('MarketData.form.value.label')}
                      placeholder={t('MarketData.form.value.placeholder')}
                      keyboardType='default'
                      onChange={onChange}
                      onFocus={() => {
                        clearErrors('value')
                        setError('')
                      }}
                      onBlur={onBlur}
                    />
                  </InputGroup>
                )}
              />
              <FormControl.ErrorMessage
                testID="md-valueError-message"
                leftIcon={<WarningOutlineIcon size="xs" />}
              >
                {errors.value?.message}
              </FormControl.ErrorMessage>
            </FormControl>
          </FieldContainer>
          <ButtonContainer>
            <SharedButton
              testID="md-submit-button"
              onPress={handleSubmitForm(handleSubmit)}
              loading={isLoadingActionMarketData}
              disabled={isLoadingActionMarketData}
            >
              {t('Components.button.save')}
            </SharedButton>
            <SharedButton
              testID="md-reset-button"
              onPress={() => {
                formReset()
                setError('')
              }}
              variant='secondary'
              disabled={isLoadingActionMarketData}
            >
              {t('Components.button.reset')}
            </SharedButton>
          </ButtonContainer>
          <Error>
            {error}
          </Error>
        </FormContent>
        <TableContent>
          <View>
            <H2
              testID="md-market-date-title"
              style={{
                marginLeft: 8
              }}
            >
              {t('MarketData.titleData')}
            </H2>
          </View>
          <View style={{
            marginHorizontal: 16,
            marginBottom: 8,
            flexDirection: 'row',
            justifyContent: 'space-evenly'
          }}
          >
            <Title>{t('MarketData.table.date')}</Title>
            <Title>{t('MarketData.table.price')}</Title>
          </View>
          <ListContainer>
            {
              isLoading
                ? (
                  <View style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%'
                  }}
                  >
                    <ActivityIndicator
                      color={colors.primary}
                      size='large'
                    />
                  </View>
                  )
                : marketData.length === 0
                  ? (
                    <View style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      width: '100%'
                    }}
                    >
                      <H3>
                        { !selectedProduct
                          ? t('MarketData.table.emptyProduct')
                          : t('MarketData.table.emptyDataByProduct') }
                      </H3>
                    </View>
                    )
                  : (
                    <FlatList
                      testID="md-filtered-flatList"
                      data={marketData}
                      renderItem={renderItem}
                      keyExtractor={data => data.id}
                      scrollEnabled
                      style={{
                        height: '100%',
                        width: '100%'
                      }}
                    />
                    )
            }
          </ListContainer>
        </TableContent>
        {
          priceConfirmationModalVisible && (
            <Modal
              title={t('Components.modal.priceConfirmation.title')}
              variant='error'
              visible={priceConfirmationModalVisible}
              isLoading={isLoadingActionMarketData}
              buttons={[
                {
                  label: t('Components.button.cancel').toString(),
                  onPress: closePriceConfirmationModal,
                  testID: 'ps-priceCancel-button',
                  variant: 'secondary'
                },
                {
                  label: t('Components.button.confirm').toString(),
                  onPress: handleSubmitForm(confirmMarketDataAction),
                  testID: 'ps-priceConfirm-button',
                  variant: 'primary'
                }
              ]}
            />
          )
        }
        {
          successModalVisible && (
            <Modal
              title={t('MarketData.modal.success')}
              variant='success'
              visible={successModalVisible}
              buttons={[
                {
                  label: t('Components.button.ok').toString(),
                  onPress: closeSuccessModal,
                  testID: 'ps-success-button',
                  variant: 'primary'
                }
              ]}
            />
          )
        }
      </SubContent>
    </Content>
  )
}

export default MarketDataScreen
