/* eslint-disable import/no-duplicates */
import { format } from 'date-fns'
import { es, enUS } from 'date-fns/locale'

import i18n from './i18n'

import { UserRole } from '../enums/user-role'

export type TextTranslationProp = {
  es: string;
  en: string;
}
const language = i18n.language

export const getTranslation = (text: TextTranslationProp) => {
  return text?.[language as keyof TextTranslationProp]
}

export const setLanguageLocale = () => {
  if (language === 'es') return es
  if (language === 'en') return enUS
}

export const getRoleTranslation = (role: UserRole) => {
  if (role === UserRole.farmer) return i18n.t('UserRole.farmer')
  if (role === UserRole.broker) return i18n.t('UserRole.broker')
  if (role === UserRole.broker_farmer) return i18n.t('UserRole.broker_farmer')
}

export const getMonthTranslation = (month: number) => {
  return format(month, 'MMM', { locale: setLanguageLocale() })
}

export const getMonth = (month: number) => {
  return i18n.t(`Components.card.monthAbbr.${month}`)
}

export const getDate = (date: Date | string) => {
  if (language === 'en') {
    return format(new Date(date), 'MM/dd/yyyy')
  }
  if (language === 'es') {
    return format(new Date(date), 'dd/MM/yyyy')
  }
  return format(new Date(date), 'MM/dd/yyyy')
}
