import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { View, FormControl } from 'native-base'
import { theme } from '@hedgit/lib/theme'
import omit from 'lodash.omit'

import { phoneNumberRegex } from '@hedgit/lib/utils/regex/index'

import { SharedInput } from '@hedgit/lib/components/shared/input'
import PencilIcon from '@hedgit/lib/components/icons/pencil'
import Body from '@hedgit/lib/components/typography/body'
import AddIcon from '@hedgit/lib/components/icons/add'
import MinusIcon from '@hedgit/lib/components/icons/minus'
import ErrorIcon from '@hedgit/lib/components/icons/error'

import { AddButton, InputContainer, DeleteButton, ContainerError } from './styled'

export const formatPhoneNumber = (number: string) => {
  const formattedNumber = number.replace(/[^\d.]/g, '')
  return formattedNumber
}

interface MultiplePhoneNumbersInputProps {
  values: string[];
  errors: Record<number, string>;
  setValues: (values: string[]) => void;
  setErrors: (errors: Record<number, string>) => void;
}

const MultiplePhoneNumbersInput = ({
  values, errors, setValues, setErrors
}: MultiplePhoneNumbersInputProps) => {
  const { t } = useTranslation()

  const handleValueChange = useCallback(
    (index: number, value: string) => {
      const newValues = [
        ...values.slice(0, index),
        value,
        ...values.slice(index + 1)
      ]
      setValues(newValues)

      if (errors[index]) {
        setErrors(omit(errors, index))
      }
    },
    [values, errors, setValues, setErrors]
  )
  const handleInputBlur = useCallback((value: string) => {
    const isValid = phoneNumberRegex.test(value)

    if (!isValid) {
      const newErrors = {
        ...errors,
        [value]: value.length > 1 ? t('Settings.form.phone.error') : t('ErrorMessages.phone.required')
      }
      setErrors(newErrors)
    } else if (errors[value]) {
      setErrors(omit(errors, value))
    }
  }, [errors, t, setErrors])

  const handleAddNewValue = useCallback(() => {
    const newValues = values.concat('')
    setValues(newValues)
  }, [values, setValues])

  const handleDeleteValue = useCallback((value: string) => {
    const newValues = values.filter(v => v !== value)
    setValues(newValues.length ? newValues : [''])
    setErrors(omit(errors, value))
  }, [values, setValues, errors, setErrors])

  return (
    <View>
      {values.map((value, index) => (
        <View style={{ marginBottom: 26 }}>
          <InputContainer>
            <View style={{ flex: 1 }}>
              <FormControl isInvalid={errors[value]}>
                <SharedInput
                  withLabel
                  value={formatPhoneNumber(value)}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(e) => handleValueChange(index, (e.target as any).value)}
                  maxLength={10}
                  onBlur={() => handleInputBlur(value)}
                  onFocus={() => {
                    setErrors('')
                  }}
                  keyboardType="phone-pad"
                  label={t('Settings.form.phone.label')}
                  placeholder={t('Settings.form.phone.placeholder')}
                  endAdornment={<PencilIcon width={24} height={24} color="#adb1b8" />}
                />
              </FormControl>
            </View>
            <DeleteButton
              onPress={() => handleDeleteValue(value)}
              disabled={values.length === 1}
            >
              <MinusIcon
                style={{
                  width: 36,
                  height: 36,
                  marginLeft: 12,
                  opacity: 0.8
                }}
                color={values.length === 1 ? theme.colors.disabledGray : theme.colors.red}
              />
            </DeleteButton>
          </InputContainer>
          {errors[value] && (
          <ContainerError>
            <ErrorIcon width={14} height={14} color={theme.colors.red} />
            <Body
              style={{
                color: theme.colors.red,
                paddingLeft: 5,
                fontSize: 12,
                fontFamily: 'Lato'
              }}
            >
              {errors[value]}
            </Body>
          </ContainerError>
          )}
        </View>
      ))}
      {values.length < 3 && (
      <AddButton onPress={handleAddNewValue}>
        <AddIcon style={{ width: 36, height: 36, marginRight: 16 }} color={theme.colors.primary} />
        <Body style={{ fontSize: 16 }}>Agregar numero de teléfono</Body>
      </AddButton>
      )}
    </View>
  )
}

export default MultiplePhoneNumbersInput
