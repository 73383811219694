import styled from 'styled-components/native'
import { Text } from 'native-base'

export const Container = styled.View`
  position: relative;
  z-index: 1;
  width: 100%;
`

export const EndAdornmentContainer = styled.View`
  z-index: -1;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  height: 30px;
  width: 30px;
  right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LeftAddon = styled(Text)`
  border-right-width: 1px;
  border-color: ${props => props.theme.colors.gray};
  color: ${props => props.theme.colors.gray};
  text-align: center;
  padding-top: 14px;
  height: 50px;
  width: 45px;
`
export const RightAddon = styled(Text)`
  border-left-width: 1px;
  border-color: ${props => props.theme.colors.gray};
  color: ${props => props.theme.colors.gray};
  text-align: center;
  padding-top: 14px;
  height: 50px;
  width: 45px;
`
