import { combineReducers, compose, createStore, applyMiddleware, AnyAction } from 'redux'
import { useSelector as useSelectorRedux, useDispatch as useDispatchRedux, TypedUseSelectorHook } from 'react-redux'
import thunk, { ThunkDispatch } from 'redux-thunk'
import { ThunkAction } from '@hedgit/lib/types/redux-thunk'

import {
  authReducer,
  AuthActionTypes,
  AuthState
} from '@hedgit/lib/store/modules/auth'
import {
  brokersReducer,
  BrokersActionTypes,
  BrokersState
} from '@hedgit/lib/store/modules/brokers'
import {
  cropsReducer,
  CropsActionTypes,
  CropsState
} from '@hedgit/lib/store/modules/crops'
import {
  destinationsReducer,
  DestinationsActionTypes,
  DestinationsState
} from '@hedgit/lib/store/modules/destinations'
import {
  marketDataReducer,
  MarketDataActionTypes,
  MarketDataState
} from '@hedgit/lib/store/modules/market-data'
import {
  planTypeReducer,
  PlanTypesActionTypes,
  PlanTypesState
} from '@hedgit/lib/store/modules/plan-types'
import {
  productsReducer,
  ProductsActionTypes,
  ProductsState
} from '@hedgit/lib/store/modules/products'
import {
  uiReducer,
  UIActionTypes,
  UIState
} from '@hedgit/lib/store/modules/ui'
import {
  areaCodesReducer,
  AreaCodesActionTypes,
  AreaCodesState
} from '@hedgit/lib/store/modules/area-codes'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export interface RootState {
  auth: AuthState;
  areaCodes: AreaCodesState;
  brokers: BrokersState;
  crops: CropsState;
  destinations: DestinationsState;
  marketData: MarketDataState;
  planType: PlanTypesState;
  products: ProductsState;
  ui: UIState;
}

export type RootAction =
  ThunkAction<void, RootState, unknown, AnyAction> |
  AuthActionTypes |
  BrokersActionTypes |
  CropsActionTypes |
  DestinationsActionTypes |
  MarketDataActionTypes |
  PlanTypesActionTypes |
  ProductsActionTypes |
  UIActionTypes |
  AreaCodesActionTypes;

const rootReducer = combineReducers<RootState>({
  auth: authReducer,
  areaCodes: areaCodesReducer,
  brokers: brokersReducer,
  crops: cropsReducer,
  destinations: destinationsReducer,
  marketData: marketDataReducer,
  planType: planTypeReducer,
  products: productsReducer,
  ui: uiReducer
})

const enhancer = composeEnhancers(applyMiddleware(thunk))

export const generateStore = () => {
  const store = createStore(rootReducer, enhancer)
  return store
}

export const useSelector: TypedUseSelectorHook<RootState> = useSelectorRedux

type Dispatch = <TReturnType>(action: RootAction) => TReturnType
export const useDispatch = () => useDispatchRedux<Dispatch>()
export const useThunkDispatch = () => useDispatchRedux<ThunkDispatch<RootState, undefined, AnyAction>>()
