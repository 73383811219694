/* eslint-disable max-len */
import React from 'react'
import { Svg, Path } from 'react-native-svg'

import useId from '../../hooks/useId'

interface Props {
  color: string;
  width?: string;
  height?: string;
}

const GoBackIcon = ({ color, width, height }: Props) => {
  const id = useId('ps-icon')

  return (
    <Svg id={'Backward_arrow' + id} width={width ?? '16'} height={height ?? '16'} viewBox="0 0 16 16">
      <Path id={'Path_10' + id} data-name={'Path 10' + id} d="M8,0,6.545,1.455l5.506,5.506H0V9.039H12.052L6.545,14.545,8,16l8-8Z" transform="translate(16 16) rotate(180)" fill={color} />
    </Svg>
  )
}

export default GoBackIcon
