import { Reducer } from 'redux'

import { AuthActionTypes, AuthState, AuthActionConsts } from './types'

export const initialState: AuthState = {
  isFetching: false,
  isVerified: true,
  token: '',
  user: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    firebaseUID: ''
  },
  phone: '',
  error: '',
  currentUser: undefined,
  isUpdatingUser: false,
  isDeletingUser: false,
  isValid: null,
  isSignIn: null
}

export const authReducer: Reducer<AuthState, AuthActionTypes> = (
  state = initialState,
  action
): AuthState => {
  switch (action.type) {
    case AuthActionConsts.SET_TOKEN:
      return {
        ...state,
        token: action.payload
      }
    case AuthActionConsts.SET_IS_SIGN_IN:
      return {
        ...state,
        isSignIn: action.payload
      }
    case AuthActionConsts.SAVE_USER_INFORMATION:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        }
      }
    case AuthActionConsts.SAVE_PHONE:
      return {
        ...state,
        phone: action.payload
      }
    case AuthActionConsts.SAVE_FIREBASE_UID:
      return {
        ...state,
        user: {
          ...state.user,
          firebaseUID: action.payload
        }
      }
    case AuthActionConsts.VERIFY_USER_PENDING:
      return {
        ...state,
        isFetching: true,
        isVerified: true
      }
    case AuthActionConsts.VERIFY_USER_FULFILLED:
      return {
        ...state,
        isFetching: false,
        isVerified: action.payload
      }
    case AuthActionConsts.VERIFY_USER_REJECTED:
      return {
        ...state,
        isFetching: false,
        isVerified: true,
        error: action.payload
      }
    case AuthActionConsts.GET_USER_DATA_PENDING:
    case AuthActionConsts.CREATE_FARMER_PENDING:
      return {
        ...state,
        isFetching: true,
        error: undefined
      }
    case AuthActionConsts.CREATE_FARMER_FULFILLED:
      return {
        ...state,
        isFetching: false,
        user: {
          ...state.user,
          ...action.payload
        }
      }
    case AuthActionConsts.GET_USER_DATA_REJECTED:
    case AuthActionConsts.CREATE_FARMER_REJECTED:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }
    case AuthActionConsts.GET_USER_DATA_FULFILLED:
      return {
        ...state,
        isFetching: false,
        currentUser: action.payload
      }
    case AuthActionConsts.CREATE_BROKER_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case AuthActionConsts.CREATE_BROKER_FULFILLED:
      return {
        ...state,
        isFetching: false,
        user: {
          ...state.user,
          ...action.payload
        }
      }
    case AuthActionConsts.CREATE_BROKER_REJECTED:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }
    case AuthActionConsts.UPDATE_FARMER_PENDING:
      return {
        ...state,
        isUpdatingUser: true,
        error: undefined
      }
    case AuthActionConsts.UPDATE_FARMER_REJECTED:
      return {
        ...state,
        isUpdatingUser: false,
        error: action.payload
      }
    case AuthActionConsts.UPDATE_FARMER_FULFILLED:
      return {
        ...state,
        currentUser: action.payload,
        isUpdatingUser: false
      }
    case AuthActionConsts.UPDATE_BROKER_PENDING:
      return {
        ...state,
        isFetching: true,
        isUpdatingUser: true,
        error: undefined
      }
    case AuthActionConsts.UPDATE_BROKER_REJECTED:
      return {
        ...state,
        isFetching: false,
        isUpdatingUser: false,
        error: action.payload
      }
    case AuthActionConsts.UPDATE_BROKER_FULFILLED:
      return {
        ...state,
        isFetching: false,
        currentUser: action.payload,
        isUpdatingUser: false,
        error: undefined
      }
    case AuthActionConsts.DELETE_BROKER_PENDING:
      return {
        ...state,
        isDeletingUser: true
      }
    case AuthActionConsts.DELETE_BROKER_REJECTED:
      return {
        ...state,
        isDeletingUser: false,
        error: action.payload
      }
    case AuthActionConsts.DELETE_BROKER_FULFILLED:
      return {
        ...state,
        isDeletingUser: false
      }
    case AuthActionConsts.VERIFY_AREA_CODE_PENDING:
      return {
        ...state,
        isFetching: true,
        isValid: false
      }
    case AuthActionConsts.VERIFY_AREA_CODE_FULFILLED:
      return {
        ...state,
        isFetching: false,
        isValid: action.payload
      }
    case AuthActionConsts.VERIFY_AREA_CODE_REJECTED:
      return {
        ...state,
        isFetching: false,
        isValid: false
      }
    case AuthActionConsts.CLEAN_VERIFY_AREA_CODE:
      return {
        ...state,
        isValid: null
      }
    case AuthActionConsts.SIGN_OUT:
      return {
        ...initialState,
        isSignIn: false
      }
    default:
      return state
  }
}
