import React, { ChangeEvent, useEffect, useState } from 'react'

import { Input } from './styled'

export function DebouncedInput ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value.normalize('NFC').replace(/[^´a-z0-9À-ž ]/gi, ''))
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [debounce, onChange, value])

  return (
    <Input
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      value={value}
      onChange={handleChange}
    />
  )
}
