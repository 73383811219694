import React, { HTMLProps, FC, FormEventHandler } from 'react'
import { theme } from '@hedgit/lib/theme'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'

import { Country } from '@hedgit/lib/enums/country'

import { Broker } from '@hedgit/lib/interfaces/broker'
import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'

import AddIcon from '@hedgit/lib/components/icons/add'
import ErrorIcon from '@hedgit/lib/components/icons/error'

import { CheckboxWrapper, IconButton } from './styled'

export interface AreaCodeData {
  id: string;
  prefix: number;
  city: string;
  province: string;
  country: Country;
  broker?: Broker;
  brokerName?: string;
}

interface SelectCellProps {
  row: {
    getIsSelected: () => boolean;
    getCanSelect: () => boolean;
    getIsSomeSelected: () => boolean;
    getToggleSelectedHandler: () => FormEventHandler<HTMLInputElement> | undefined;
  };
}

interface ActionCellProps {
  row: {
    original: AreaCodeData;
  };
  onDelete: () => void;
  disabled?: boolean;
}

type NavigationType = NativeStackNavigationProp<RootStackParamList, 'Brokers'>

const { colors } = theme

export const IndeterminateCheckbox = ({
  indeterminate,
  checked,
  disabled,
  onChange
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) => {
  const ref = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean' && ref.current) {
      ref.current.indeterminate = !checked && indeterminate
    }
  }, [ref, indeterminate, checked])

  return (
    <input
      type="checkbox"
      ref={ref}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
    />
  )
}

export const SelectCell: FC<SelectCellProps> = ({ row }) => (
  <CheckboxWrapper>
    <IndeterminateCheckbox
      checked={row.getIsSelected()}
      disabled={!row.getCanSelect()}
      indeterminate={row.getIsSomeSelected()}
      onChange={row.getToggleSelectedHandler()}
    />
  </CheckboxWrapper>
)

export const ActionCell: FC<ActionCellProps> = ({ row, onDelete, disabled }) => {
  const navigation = useNavigation<NavigationType>()

  const addOnPressHandler = () => navigation.navigate('AssignAreaCode', { areaCodes: [row.original] })

  return (
    row.original.broker
      ? (
        <IconButton
          onPress={onDelete}
          testID="delete-button"
          disabled={disabled}
        >
          <ErrorIcon width={20} height={20} color={disabled ? colors.lightGray : colors.red} />
        </IconButton>
        )
      : (
        <IconButton
          onPress={addOnPressHandler}
          testID="add-button"
          disabled={disabled}
        >
          <AddIcon style={{ width: 20, height: 20 }} color={disabled ? colors.lightGray : colors.primary} />
        </IconButton>
        )
  )
}

export const PAG_SIZE_DEFAULT_VALUE = {
  value: 10,
  label: 10
}
