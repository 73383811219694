import { action } from 'typesafe-actions'

import { AreaCode } from '@hedgit/lib/interfaces/area-code'

import { AreaCodesActionConsts } from './types'

export const getAreaCodesPending = () =>
  action(AreaCodesActionConsts.GET_AREA_CODES_PENDING)

export const getAreaCodesFulfilled = (areaCodes: AreaCode[]) =>
  action(AreaCodesActionConsts.GET_AREA_CODES_FULFILLED, areaCodes)

export const getAreaCodesFailed = (error: string) =>
  action(AreaCodesActionConsts.GET_AREA_CODES_FAILED, error)

export const getAreaCodesByBrokerPending = () =>
  action(AreaCodesActionConsts.GET_AREA_CODES_BY_BROKER_PENDING)

export const getAreaCodesByBrokerFulfilled = () =>
  action(AreaCodesActionConsts.GET_AREA_CODES_BY_BROKER_FULFILLED)

export const getAreaCodesByBrokerFailed = (error: string) =>
  action(AreaCodesActionConsts.GET_AREA_CODES_BY_BROKER_FAILED, error)

export const assignAreaCodesPending = () =>
  action(AreaCodesActionConsts.ASSIGN_AREA_CODES_PENDING)

export const assignAreaCodesFulfilled = () =>
  action(AreaCodesActionConsts.ASSIGN_AREA_CODES_FULFILLED)

export const assignAreaCodesFailed = (error: string) =>
  action(AreaCodesActionConsts.ASSIGN_AREA_CODES_FAILED, error)

export const removeAreaCodesPending = () =>
  action(AreaCodesActionConsts.REMOVE_AREA_CODES_PENDING)

export const removeAreaCodesFulfilled = (areaCodes: AreaCode[]) =>
  action(AreaCodesActionConsts.REMOVE_AREA_CODES_FULFILLED, areaCodes)

export const removeAreaCodesFailed = (error: string) =>
  action(AreaCodesActionConsts.REMOVE_AREA_CODES_FAILED, error)
