import React, { FC } from 'react'
import { theme } from '@hedgit/lib/theme'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { TouchableOpacity } from 'react-native'
import { Tooltip } from 'native-base'
import { useTranslation } from 'react-i18next'

import { Status } from '@hedgit/lib/enums/status'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'
import { BrokerWithSubscriptionPlan } from '@hedgit/lib/interfaces/broker'

import ErrorIcon from '@hedgit/lib/components/icons/error'
import PowerIcon from '@hedgit/lib/components/icons/power'
import PencilIcon from '@hedgit/lib/components/icons/pencil'

import { IconContent } from './table/styled'

interface ActionCellProps {
  row: {
    original: BrokerWithSubscriptionPlan;
  };
  setOnCloseDeleteModal: (boolean: boolean) => void;
  setOnCloseModal: (boolean: boolean) => void;
  setDeleteBrokerId: (id: string) => void;
  setSuspendBrokerId: (id: string) => void;
}

const { colors } = theme

type NavigationType = NativeStackNavigationProp<RootStackParamList, 'Brokers'>

export const ActionCell: FC<ActionCellProps> = ({
  row,
  setOnCloseDeleteModal,
  setOnCloseModal,
  setDeleteBrokerId,
  setSuspendBrokerId
}) => {
  const navigation = useNavigation<NavigationType>()

  const broker = row.original
  const onUpdatePressHandler = () => {
    navigation.navigate('BrokersForm', {
      broker: broker
    })
  }

  const { t } = useTranslation()

  return (
    <IconContent>
      <Tooltip
        label={broker?.status === Status.active
          ? t('Brokers.table.suspend')
          : t('Brokers.table.activate')}
        openDelay={200}
        bg={colors.dark}
        _text={{
          color: colors.white
        }}
        placement="top"
      >
        <TouchableOpacity
          onPress={() => {
            setSuspendBrokerId(broker.id)
            setOnCloseModal(true)
          }}
          style={{ alignItems: 'center' }}
          testID="update-button"
        >
          <PowerIcon
            width={20}
            height={20}
            color={broker.status === Status.suspended ? colors.green : colors.yellow}
          />
        </TouchableOpacity>
      </Tooltip>
      <Tooltip
        label={t('Brokers.table.edit')}
        openDelay={200}
        bg={colors.dark}
        _text={{
          color: colors.white
        }}
        placement="top"
      >
        <TouchableOpacity
          onPress={() => onUpdatePressHandler()}
          style={{ alignItems: 'center' }}
          testID="update-button"
        >
          <PencilIcon width={20} height={20} color={colors.gray} />
        </TouchableOpacity>
      </Tooltip>
      <Tooltip
        label={t('Brokers.table.delete')}
        openDelay={200}
        bg={colors.dark}
        _text={{
          color: colors.white
        }}
        placement="top"
      >
        <TouchableOpacity
          onPress={() => {
            setDeleteBrokerId(broker.id)
            setOnCloseDeleteModal(true)
          }}
          style={{ alignItems: 'center' }}
          testID="delete-button"
        >
          <ErrorIcon width={20} height={20} color={colors.red} />
        </TouchableOpacity>
      </Tooltip>
    </IconContent>
  )
}
