import React, { useCallback, useMemo, useState } from 'react'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { ActivityIndicator } from 'react-native'
import { useSelector, useDispatch } from '@hedgit/admin/src/store'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { theme } from '@hedgit/lib/theme'
import { useTranslation } from 'react-i18next'
import { APIResponse } from '@hedgit/lib/types/api'
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable
} from '@tanstack/react-table'

import { getBrokers } from '@hedgit/lib/store/modules/brokers/thunks'

import { patch } from '@hedgit/lib/utils/axios'

import { Status } from '@hedgit/lib/enums/status'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'
import { BrokerWithSubscriptionPlan } from '@hedgit/lib/interfaces/broker'

import { Modal } from '@hedgit/lib/components/modals/basic-modal'
import Button from '@hedgit/lib/components/typography/button-text'
import H1 from '@hedgit/lib/components/typography/h1'

import { DebouncedInput } from 'routes/area-codes/debounced-input'

import useCustomToast from 'hooks/use-custom-toast'

import {
  AddButton,
  BrokersTableContent,
  Content,
  HeaderContent,
  SearchAndButtonContent,
  SearchContent,
  SubContent,
  TableContent,
  Td,
  TextNotFound,
  Th,
  Thead,
  Tr
} from './styled'

import { ActionCell } from '../constants'

type NavigationType = NativeStackNavigationProp<RootStackParamList, 'BrokersTable'>

const { colors } = theme

interface UpdateBrokerResponse {
  broker: BrokerWithSubscriptionPlan;
}

const BrokersTable = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigation = useNavigation<NavigationType>()
  const brokers = useSelector(store => store.brokers.brokerList)
  const isLoading = useSelector(store => store.brokers.isFetching)
  const [suspendBrokerId, setSuspendBrokerId] = useState<string>()
  const [deleteBrokerId, setDeleteBrokerId] = useState<string>()
  const [onCloseModal, setOnCloseModal] = useState(false)
  const [onCloseDeleteModal, setOnCloseDeleteModal] = useState(false)
  const [isLoadingSuspend, setIsLoadingSuspend] = useState(false)
  const [globalFilter, setGlobalFilter] = useState('')
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

  const { showToast } = useCustomToast()

  const brokersData = useMemo(() => {
    return brokers.map(broker => ({
      ...broker,
      planType: broker.subscriptionPlan?.planType.name ?? t('Brokers.table.withoutSubscriptionPlan')
    }))
  }, [brokers, t])

  useFocusEffect(
    useCallback(() => {
      dispatch(getBrokers())
    }, [dispatch])
  )

  const onDelete = async () => {
    try {
      if (!deleteBrokerId) return
      await patch<APIResponse<UpdateBrokerResponse>>(`/brokers/${deleteBrokerId}/action/delete`)
      showToast(t('Brokers.delete.success'), 'success')
      dispatch(getBrokers())
      setOnCloseDeleteModal(false)
    } catch (e) {
      showToast(t('Brokers.delete.error'), 'danger')
      console.error(e)
    }
  }

  const onSuspendBroker = async () => {
    try {
      if (!suspendBrokerId) return
      setIsLoadingSuspend(true)
      if (selectedSuspendBroker?.status === Status.suspended) {
        await patch<APIResponse<UpdateBrokerResponse>>(`/brokers/${suspendBrokerId}/action/activate`)
        showToast(t('Brokers.table.suspendModal.successActive'), 'success')
      } else {
        await patch<APIResponse<UpdateBrokerResponse>>(`/brokers/${suspendBrokerId}/action/suspend`)
        showToast(t('Brokers.table.suspendModal.successSuspend'), 'success')
      }
      dispatch(getBrokers())
      setOnCloseModal(false)
      setIsLoadingSuspend(false)
    } catch (e) {
      showToast(t('Brokers.table.suspendModal.error'), 'danger')
      console.error(e)
    } finally {
      setIsLoadingSuspend(false)
    }
  }

  const handleCancelation = () => {
    setOnCloseModal(false)
    setOnCloseDeleteModal(false)
  }

  const selectedSuspendBroker = brokers.find((b) => b.id === suspendBrokerId)
  const selectedDeleteBroker = brokers.find((b) => b.id === deleteBrokerId)

  const suspendBrokerName = `${selectedSuspendBroker?.firstName} ${selectedSuspendBroker?.lastName}`
  const deleteBrokerName = `${selectedDeleteBroker?.firstName} ${selectedDeleteBroker?.lastName}`

  const activeBroker =
    t('Brokers.table.suspendModal.active') + suspendBrokerName + t('Brokers.table.suspendModal.second')
  const suspendBroker =
    t('Brokers.table.suspendModal.suspend') + suspendBrokerName + t('Brokers.table.suspendModal.second')

  const ActionCellComponent = useCallback(({ row }) => (
    <ActionCell
      row={row}
      setOnCloseDeleteModal={setOnCloseDeleteModal}
      setDeleteBrokerId={setDeleteBrokerId}
      setSuspendBrokerId={setSuspendBrokerId}
      setOnCloseModal={setOnCloseModal}
    />
  ), [])

  const columns = useMemo<ColumnDef<BrokerWithSubscriptionPlan>[]>(() => {
    return [
      {
        accessorKey: 'lastName',
        header: t('Brokers.table.name'),
        size: 80
      },
      {
        accessorKey: 'phone',
        header: t('Brokers.table.phone')
      },
      {
        accessorKey: 'email',
        header: t('Brokers.table.email'),
        size: 100
      },
      {
        accessorKey: 'role',
        header: t('Brokers.table.role'),
        size: 100
      },
      {
        accessorKey: 'status',
        header: t('Brokers.table.status'),
        size: 100
      },
      {
        accessorKey: 'planType',
        header: t('Brokers.table.planType'),
        size: 100
      },
      {
        id: 'action',
        cell: ActionCellComponent,
        size: 20,
        maxSize: 20
      }
    ]
  }, [ActionCellComponent, t])

  const table = useReactTable({
    data: brokersData,
    columns,
    state: {
      columnFilters,
      globalFilter
    },
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
    onColumnFiltersChange: setColumnFilters,
    globalFilterFn: (row, columnId, filterValue) => {
      const formattedFilterValue = filterValue
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '')
        .toLowerCase()
      const cellValue = row.getValue(columnId)
      if (typeof cellValue !== 'string' && typeof cellValue !== 'number') return false
      const formattedDataValue = cellValue.toString()
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '')
        .toLowerCase()
      return formattedDataValue.includes(formattedFilterValue)
    }
  })

  return (
    <Content>
      <SubContent>
        <HeaderContent>
          <H1>Partners</H1>
          <SearchAndButtonContent>
            <SearchContent>
              <DebouncedInput
                value={globalFilter ?? ''}
                onChange={(value) => setGlobalFilter((String(value)))}
                placeholder={t('Components.searchBar.placeholder')}
              />
            </SearchContent>
            <AddButton
              testID='add-broker-button'
              onPress={() => navigation.navigate('BrokersForm')}
            >
              <Button style={{ color: '#fff' }}>{t('Components.button.add')}</Button>
            </AddButton>
          </SearchAndButtonContent>
        </HeaderContent>
        {isLoading
          ? (
            <ActivityIndicator
              color={colors.primary}
              size='large'
              style={{
                margin: 16,
                justifyContent: 'center',
                alignItems: 'center'
              }}
            />
            )
          : (
              table.getRowModel().rows.length === 0 && (
              <TextNotFound>{t('Brokers.table.notFound')}</TextNotFound>
              )
            )}
        {(!isLoading && table.getRowModel().rows.length > 0) && (
          <TableContent>
            <BrokersTableContent>
              <Thead>
                {table.getHeaderGroups().map(headerGroup => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => {
                      return (
                        <Th key={header.id} colSpan={header.colSpan}>
                          {header.isPlaceholder
                            ? null
                            : (
                              <>
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                              </>
                              )}
                        </Th>
                      )
                    })}
                  </Tr>
                ))}
              </Thead>
              <tbody>
                {table.getRowModel().rows.map(row => {
                  return (
                    <Tr key={row.id}>
                      {row.getVisibleCells().map(cell => {
                        return (
                          <Td key={cell.id} style={{ width: cell.column.getSize() }}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Td>
                        )
                      })}
                    </Tr>
                  )
                })}
              </tbody>
            </BrokersTableContent>
          </TableContent>
        )}
      </SubContent>
      <Modal
        title={t('Brokers.table.deleteModal.first') + deleteBrokerName + t('Brokers.table.deleteModal.second')}
        variant="confirm"
        visible={onCloseDeleteModal}
        buttons={[
          {
            label: t('Components.button.no'),
            onPress: handleCancelation,
            testID: 'no-delete-broker-modal-button',
            variant: 'secondary'
          },
          {
            label: t('Components.button.yes'),
            onPress: onDelete,
            testID: 'yes-delete-broker-modal-button',
            variant: 'primary'
          }
        ]}
      />
      <Modal
        title={
          selectedSuspendBroker?.status === Status.active ? suspendBroker : activeBroker
        }
        variant="confirm"
        visible={onCloseModal}
        buttons={[
          {
            label: t('Components.button.no'),
            onPress: handleCancelation,
            testID: 'no-delete-broker-modal-button',
            variant: 'secondary',
            isDisabled: isLoadingSuspend
          },
          {
            label: t('Components.button.yes'),
            onPress: onSuspendBroker,
            testID: 'yes-delete-broker-modal-button',
            variant: 'primary',
            isDisabled: isLoadingSuspend,
            isLoading: isLoadingSuspend
          }
        ]}
      />
    </Content>
  )
}

export default BrokersTable
