import { useToast } from 'react-native-toast-notifications'

const useCustomToast = () => {
  const toast = useToast()

  const showToast = (message: string, type: 'normal' | 'success' | 'danger' | 'warning' = 'normal') => {
    toast.show(message, {
      type: type,
      placement: 'bottom',
      duration: 4000,
      animationType: 'slide-in'
    })
  }

  return {
    showToast
  }
}

export default useCustomToast
