import React from 'react'
import { ThemeProvider } from 'styled-components/native'
import { Provider } from 'react-redux'
import { NativeBaseProvider } from 'native-base'
import { theme } from '@hedgit/lib/theme'
import { ToastProvider } from 'react-native-toast-notifications'

import { generateStore } from './store'
import Navigator from './routes'

const store = generateStore()

const App = () => {
  return (
    <Provider store={store}>
      <ToastProvider>
        <ThemeProvider theme={theme}>
          <NativeBaseProvider theme={theme}>
            <Navigator />
          </NativeBaseProvider>
        </ThemeProvider>
      </ToastProvider>
    </Provider>
  )
}

export default App
