import styled from 'styled-components/native'
import webStyled from 'styled-components'

import H2 from '@hedgit/lib/components/typography/h2'

export const Content = styled.View`
  justify-content: center;
  align-items: center;
  max-height: 100%;
  width: 100%;
  background-color: ${props => props.theme.colors.whiteMiddle};
  `

export const SubContent = styled.View`
  padding: 16px;
  height: 100%;
  background-color: ${props => props.theme.colors.whiteMiddle};
`

export const HeaderContent = styled.View`
  width: 1000px;
  padding: 0 8px 16px 8px;
  flex-direction: row;
  justify-content: space-between;
`

export const TableContent = styled.View`
  border-radius: 10px;
  flex: 1;
  flex-basis: 100%;
  overflow: scroll;
`

export const AddButton = styled.TouchableOpacity`
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 32px;
  border-radius: 10px;
  background-color: ${props => props.theme.colors.primary};
`
export const IconContent = styled.View`
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
`
export const SearchContent = styled.View`
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  padding: 10px;
  border-radius: 15px;
`

export const SearchAndButtonContent = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`
export const TextNotFound = styled(H2)`
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-left: 8px;
`
export const SignalsTable = webStyled.table`
  justify-content: baseline;
  align-items: center;
  width: 1000px;
  max-width: 1000px;
  padding: 16px;
  padding-top: 0;
  box-shadow: 0 3px 6px #0000001A;
  border-radius: 10px;
  font-family: Lato;
  color: #313C4D;
  background-color: white;
  overflow: scroll;
`
export const TablePaginationWrapper = styled.View`
  flex: 1;
  flex-basis: 0%;
`

export const Thead = webStyled.thead`
  position: sticky;
  top: 0;
  background-color: white;
`

export const Th = webStyled.th`
  font-size: 18px;
  padding: 8px;
  padding-top: 16px;
  text-align: left;
  border-bottom: 1px solid #107DB2;
`

export const Td = webStyled.td`
  font-size: 14px;
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #107DB2;
`

export const Tr = webStyled.tr`
`

export const TdIcon = webStyled(Td)`
  flex-direction: row;
  justify-content: center;
`
