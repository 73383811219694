import styled from 'styled-components/native'

import Body from '../../typography/body'

export const Container = styled.View`
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 100%;
  max-width: 600px;
  align-self: center;
`

export const SubContainer = styled.View`
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 100%;
  width: 100%;
  height: auto;
  gap: 26px;
`

export const InputWrapper = styled.View`
  height: 100%;
  justify-content: flex-start;
`

export const InputContainer = styled.View`
  width: 100%;
  max-width: 328px;
  height: 72px;
  margin-top: 80px;
  padding-top: 5px;
  border-radius: 15px;
  background-color: #f3f3f3;
  align-items: center;
`

// ----- Calculations made to define input elements width and spacing
// const charW = '1ch'
// const gap = '0.5ch' // 0.5 * charW
// const nChar = 6
// const totalWidth = '9ch'// $n-char*($char-w + $gap);

export const StyledInput = styled.TextInput`
  font-family: monospace;
  font-size: 45px;
  letter-spacing: 0.5ch;
  outline-width: 0;
  width: 9ch;
  caret-color: transparent;
  margin-left: 0.5ch;
`

export const UnderlineContainer = styled.View`
  font-family: monospace;
  font-size: 45px;
  flex-direction: row;
  margin-top: 0;
  align-self: center;
  width: 9ch;
  gap: 0.5ch;
  margin-left: 0.5ch;
`

export const Underline = styled.View`
  font-family: monospace;
  font-size: 45px;
  width: 1ch;
  height: 2px;
`

export const RequirementsContainer = styled.View`
  align-items: center;
  max-width: 328px;
  width: 100%;
  margin-top: 30px;
`

export const Requirements = styled(Body)`
  color: ${props => props.theme.colors.gray};
`

export const RequirementsBold = styled(Body)`
  color: ${props => props.theme.colors.dark};
  font-weight: bold;
`

export const TextErrorCodeInvalid = styled(Body)`
  color: ${props => props.theme.colors.red};
`

export const ButtonContainer = styled.View`
  width: 100%;
  gap: 20px;
`

export const CodeContainer = styled.View`
  align-items: center;
`
