import * as yup from 'yup'
import i18n from '@hedgit/lib/translations/i18n'

import { dateRegex } from '@hedgit/lib/utils/regex'

export const formValidationSchema = yup.object({
  date: yup
    .string()
    .required(i18n.t('MarketData.form.date.required'))
    .matches(dateRegex, i18n.t('MarketData.form.date.matches')),
  product: yup
    .string()
    .required(i18n.t('MarketData.form.product.required')),
  value: yup
    .number()
    .positive(i18n.t('MarketData.form.value.positive'))
    .required(i18n.t('MarketData.form.value.required'))
    .typeError(i18n.t('MarketData.form.value.typeError'))
})
