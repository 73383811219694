/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import axios, { AxiosRequestConfig } from 'axios'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { getAuth } from '@firebase/auth'

import { setAuthToken, signOutThunk } from '@hedgit/lib/store/modules/auth'

import { useDispatch } from 'store'

const useAuthToken = () => {
  const dispatch = useDispatch()

  const getInitialToken = async () => {
    try {
      const token = await AsyncStorage.getItem('auth_token')
      if (token) {
        dispatch(setAuthToken(token))
      }
    } catch (e) {
      console.log(e)
    }
  }

  const updateToken = (value: string) => {
    AsyncStorage.setItem('auth_token', value)
    dispatch(setAuthToken(value))
  }

  useEffect(() => {
    getInitialToken()
  }, [])

  useEffect(() => {
    getAuth().onAuthStateChanged(async (user) => {
      try {
        if (user) {
          const newToken = await user?.getIdToken()
          updateToken(newToken)
        }
      } catch (error) {
        console.error(error)
      }
    })

    axios.interceptors.response.use((res) => {
      if (res.headers.RefreshToken) {
        const newToken = res.headers.RefreshToken
        updateToken(newToken)
      }
      if (res.data.statusCode === 401 || res.data.error === 'Unauthorized') {
        dispatch(signOutThunk())
      }
      return res
    }, (err) => {
      if (err.response.status === 401 || err.response.statusText === 'Unauthorized') {
        dispatch(signOutThunk())
      }
      return Promise.reject(err)
    })

    axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
      const t = await AsyncStorage.getItem('auth_token')
      if (config.headers) {
        config.headers.Authorization = `Bearer ${t}`
      }
      return config
    })
  }, [])
}

export default useAuthToken
