export const download = (fileName: string, content: string) => {
  const a = document.createElement('a')

  const blob = new Blob([content], {
    type: 'text/plain'
  })

  a.href = window.URL.createObjectURL(blob)
  a.download = fileName
  a.style.display = 'none'
  document.body.appendChild(a)
  a.click()
}
