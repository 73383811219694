import styled from 'styled-components/native'

export const Title = styled.Text`
  font-size: 24px;
  font-weight: bold;
  padding: 10px;
`

export const Content = styled.View`
  background-color: ${props => props.theme.colors.white};
  justify-content: center;
  height: 100%;
`

export const BackButtonContainer = styled.View`
  position: absolute;
  flex-direction: row;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 64px;
  padding-left: 16px;
`
