import { Reducer } from 'redux'

import { AreaCodesActionTypes, AreaCodesState, AreaCodesActionConsts } from './types'

export const initialState: AreaCodesState = {
  list: [],
  isFetching: false,
  error: ''
}

export const areaCodesReducer: Reducer<AreaCodesState, AreaCodesActionTypes> = (
  state = initialState,
  action
): AreaCodesState => {
  switch (action.type) {
    case AreaCodesActionConsts.GET_AREA_CODES_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case AreaCodesActionConsts.GET_AREA_CODES_FULFILLED:
      return {
        ...state,
        isFetching: false,
        list: action.payload
      }
    case AreaCodesActionConsts.GET_AREA_CODES_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }
    case AreaCodesActionConsts.GET_AREA_CODES_BY_BROKER_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case AreaCodesActionConsts.GET_AREA_CODES_BY_BROKER_FULFILLED:
      return {
        ...state,
        isFetching: false
      }
    case AreaCodesActionConsts.GET_AREA_CODES_BY_BROKER_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }
    case AreaCodesActionConsts.REMOVE_AREA_CODES_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case AreaCodesActionConsts.REMOVE_AREA_CODES_FULFILLED:
      return {
        ...state,
        isFetching: false,
        list: state.list.map((areaCode) => {
          const areaCodeFound = action.payload.find(areaCodeUpdated => areaCodeUpdated.id === areaCode.id)

          return areaCodeFound || areaCode
        })
      }
    case AreaCodesActionConsts.REMOVE_AREA_CODES_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }
    case AreaCodesActionConsts.ASSIGN_AREA_CODES_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case AreaCodesActionConsts.ASSIGN_AREA_CODES_FULFILLED:
      return {
        ...state,
        isFetching: false
      }
    case AreaCodesActionConsts.ASSIGN_AREA_CODES_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }
    default:
      return state
  }
}
