/* eslint-disable max-len */
import React from 'react'
import { Svg, Path, G } from 'react-native-svg'

import { IconProps } from './types'

const PowerIcon = ({ color, height, width }: IconProps) => {
  return (
    <Svg width={width} height={height} x="0px" y="0px" viewBox="0 0 512 512">
      <G>
        <Path
          fill={color}
          d="M256,512C128.502,512,24.774,408.272,24.774,280.774c0-84.49,46.065-162.23,120.216-202.879
          c12.006-6.577,27.057-2.18,33.633,9.816c6.577,11.997,2.182,27.055-9.814,33.633c-58.282,31.949-94.487,93.039-94.487,159.43
          c0,100.177,81.5,181.677,181.677,181.677s181.677-81.5,181.677-181.677c0-66.682-36.44-127.899-95.097-159.764
          c-12.022-6.532-16.475-21.573-9.943-33.595s21.572-16.475,33.595-9.944c74.631,40.542,120.992,118.444,120.992,203.304
          C487.226,408.272,383.498,512,256,512z"
        />
        <Path
          fill={color}
          d="M256,214.71c-13.682,0-24.774-11.092-24.774-24.774V24.774C231.226,11.092,242.318,0,256,0
          c13.682,0,24.774,11.092,24.774,24.774v165.161C280.774,203.617,269.682,214.71,256,214.71z"
        />
      </G>
    </Svg>
  )
}

export default PowerIcon
