import React from 'react'
import { View } from 'react-native'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'

import { SignInVerification } from '../sign-in-verification'
import { SignIn } from '../sign-in'

const Stack = createStackNavigator()

const AuthNavigator = () => {
  return (
    <View style={{ height: '100vh' }}>
      <NavigationContainer>
        <Stack.Navigator
          screenOptions={{
            animationEnabled: true,
            headerShown: false
          }}
        >
          <Stack.Screen name="SignIn" component={SignIn} />
          <Stack.Screen name="SignInVerification" component={SignInVerification} />
        </Stack.Navigator>
      </NavigationContainer>
    </View>
  )
}

export default AuthNavigator
