import { APIResponse } from '@hedgit/lib/types/api'
import { ThunkAction } from '@hedgit/lib/types/redux-thunk'

import { get, patch } from '@hedgit/lib/utils/axios'

import { AreaCode } from '@hedgit/lib/interfaces/area-code'

import {
  getAreaCodesPending,
  getAreaCodesFulfilled,
  getAreaCodesFailed,
  getAreaCodesByBrokerPending,
  getAreaCodesByBrokerFulfilled,
  getAreaCodesByBrokerFailed,
  assignAreaCodesPending,
  assignAreaCodesFulfilled,
  assignAreaCodesFailed,
  removeAreaCodesFailed,
  removeAreaCodesFulfilled,
  removeAreaCodesPending
} from './actions'
import { AreaCodesActionTypes } from './types'

interface Response {
  areaCodes: AreaCode[];
}

interface AssignResponse {
  assignAreaCodes: AreaCode[];
}

interface ThunkResponse {
  success: boolean;
  areaCodes?: AreaCode[];
  errorMsg?: string;
}
interface RemoveResponse {
  updateAreaCodes: AreaCode[];
}

export const getAreaCodes = (filter = ''): ThunkAction<void, unknown, unknown, AreaCodesActionTypes> => {
  return async dispatch => {
    dispatch(getAreaCodesPending())
    try {
      const response = await get<APIResponse<Response>>(`/area-codes?filter=${filter}`)
      dispatch(getAreaCodesFulfilled(response.data.areaCodes))
    } catch (error) {
      dispatch(getAreaCodesFailed(error))
    }
  }
}

export const assignAreaCodes = (areaCodes, broker):
ThunkAction<Promise<ThunkResponse>, unknown, unknown, AreaCodesActionTypes> => {
  return async dispatch => {
    dispatch(assignAreaCodesPending())
    try {
      const response =
      await patch<APIResponse<AssignResponse>>('/area-codes/assign',
        {
          areaCodes: areaCodes,
          broker: broker
        })
      dispatch(assignAreaCodesFulfilled())
      return {
        areaCodes: response.data.assignAreaCodes,
        success: true
      }
    } catch (error) {
      dispatch(assignAreaCodesFailed(error as string))
      return { success: false, errorMsg: error }
    }
  }
}

export const getAreaCodesByBroker = (brokerId: string):
 ThunkAction<Promise<ThunkResponse>, unknown, unknown, AreaCodesActionTypes> => {
  return async (dispatch) => {
    dispatch(getAreaCodesByBrokerPending())
    try {
      const response = await get<APIResponse<Response>>(`/area-codes/broker/${brokerId}`)
      dispatch(getAreaCodesByBrokerFulfilled())
      return {
        areaCodes: response.data.areaCodes,
        success: true
      }
    } catch (error) {
      dispatch(getAreaCodesByBrokerFailed(error as string))
      return { success: false, errorMsg: error }
    }
  }
}

export const removeAreaCodes = (
  areaCodes: string[],
  broker: string
): ThunkAction<Promise<{success: boolean; errorMsg?: string}>, unknown, unknown, AreaCodesActionTypes> => {
  return async dispatch => {
    dispatch(removeAreaCodesPending())
    try {
      const response = await patch<APIResponse<RemoveResponse>>(
        '/area-codes/remove', {
          areaCodes, broker
        }
      )
      dispatch(removeAreaCodesFulfilled(response.data.updateAreaCodes))
      return { success: true }
    } catch (error) {
      dispatch(removeAreaCodesFailed(error))
      return { success: false, errorMsg: error }
    }
  }
}
