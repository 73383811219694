import styled from 'styled-components/native'

export const Container = styled.View`
  background-color: ${props => props.theme.colors.white};
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  gap: 40px;
  height: 100vh;
  overflow: auto;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
`

export const ImageContainer = styled.View`
  align-items: 'center';
`

export const BackgroundContainer = styled.View`
  position: fixed;
  top: 0;
  left: -70vw;
  z-index: 0;
  height: 100vh;
  min-width: 140vw;
`
