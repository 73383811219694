import * as yup from 'yup'
import { memoize } from 'lodash'

import {
  phoneNumberRegex,
  phoneNumberCountryCodeRegex,
  nameRegex,
  emailRegex,
  textRegex,
  addressRegex,
  areaCodeRegex,
  phoneRegex
} from '@hedgit/lib/utils/regex'

import i18n from '../../translations/i18n'
import { UserRole } from '../../enums/user-role'
import { post } from '../axios'
import { APIResponse } from '../../types/api'

type VerifyAreaCodeThunkResponse = {
  isValid: boolean;
  errorMsg?: string;
}

const verifyAreaCode = memoize(async (value: string, values: yup.TestContext<unknown>) => {
  try {
    const response = await post<APIResponse<VerifyAreaCodeThunkResponse>>('/area-codes/verify', { areaCode: value })
    if (response.error) throw new Error()
    return response.data.isValid
  } catch (e) {
    values.createError({ path: 'areaCode' })
    return false
  }
})

const firstName = yup
  .string()
  .min(3, i18n.t('ErrorMessages.firstName.min'))
  .trim()
  .matches(nameRegex, i18n.t('ErrorMessages.firstName.matches'))

const lastName = yup
  .string()
  .min(3, i18n.t('ErrorMessages.lastName.min'))
  .max(15, i18n.t('ErrorMessages.lastName.max'))
  .trim()
  .matches(nameRegex, i18n.t('ErrorMessages.lastName.matches'))

const email = yup
  .string()
  .trim()
  .min(3, i18n.t('ErrorMessages.email.min'))
  .matches(emailRegex, i18n.t('ErrorMessages.email.matches'))

const phone = yup
  .string()
  .min(10, i18n.t('ErrorMessages.phone.min'))
  .max(10, i18n.t('ErrorMessages.phone.max'))
  .matches(phoneNumberRegex, i18n.t('ErrorMessages.phone.matches'))

const phoneFarmer = yup
  .string()
  .min(6, i18n.t('ErrorMessages.phoneFarmer.min'))
  .test('invalidStart', i18n.t('ErrorMessages.phoneFarmer.invalidStart'), function (value) {
    if (value.startsWith('15') || value.startsWith('0')) {
      throw this.createError({ message: i18n.t('ErrorMessages.phoneFarmer.invalidStart') })
    }
    return true
  })
  .test('dynamicMax', i18n.t('ErrorMessages.phoneFarmer.min'), function (value) {
    if (this.parent.areaCode) {
      const areaCodeLength = this.parent.areaCode.length

      if (value.length === 8 && areaCodeLength === 2) return true
      if (value.length === 7 && areaCodeLength === 3) return true
      if (value.length === 6 && areaCodeLength === 4) return true

      if (value.length === 0) {
        throw this.createError({ message: i18n.t('ErrorMessages.phoneFarmer.required') })
      }
      if (areaCodeLength === 2 && value.length < 8) {
        throw this.createError({ message: i18n.t('ErrorMessages.phoneFarmer.maxOne') })
      }
      if (areaCodeLength === 3 && value.length < 7) {
        throw this.createError({ message: i18n.t('ErrorMessages.phoneFarmer.maxTwo') })
      }
      if (value.length === 4 && areaCodeLength < 6) {
        throw this.createError({ message: i18n.t('ErrorMessages.phoneFarmer.maxThree') })
      }

      return false
    }

    return true
  })
  .matches(phoneRegex, i18n.t('ErrorMessages.phoneFarmer.matches'))

const phoneCountryCode = yup
  .string()
  .matches(phoneNumberCountryCodeRegex, i18n.t('ErrorMessages.phone.matches'))

const country = yup
  .string()
  .trim()
  .required(i18n.t('ErrorMessages.country.required'))
  .matches(textRegex, i18n.t('ErrorMessages.country.matches'))

const state = yup
  .string()
  .trim()
  .required(i18n.t('ErrorMessages.state.required'))
  .matches(textRegex, i18n.t('ErrorMessages.state.matches'))

const city = yup
  .string()
  .trim()
  .required(i18n.t('ErrorMessages.city.required'))
  .matches(textRegex, i18n.t('ErrorMessages.city.matches'))

const postalCode = yup
  .string()
  .trim()
  .required(i18n.t('ErrorMessages.postalCode.required'))
  .matches(addressRegex, i18n.t('ErrorMessages.postalCode.matches'))

const address = yup
  .string()
  .trim()
  .required(i18n.t('ErrorMessages.address.required'))
  .matches(addressRegex, i18n.t('ErrorMessages.address.matches'))

const role = yup
  .string()
  .required(i18n.t('ErrorMessages.role.required'))
  .oneOf([UserRole.broker, UserRole.broker_farmer])

export const areaCode = yup
  .string()
  .min(2, i18n.t('ErrorMessages.areaCode.min'))
  .max(4, i18n.t('ErrorMessages.areaCode.max'))
  .matches(areaCodeRegex, i18n.t('ErrorMessages.areaCode.matches'))
  .test('invalidStart', i18n.t('ErrorMessages.areaCode.invalidStart'), function (value) {
    if (value.startsWith('0')) {
      throw this.createError({ message: i18n.t('ErrorMessages.areaCode.invalidStart') })
    }
    return true
  })

export const BillingInformationSchema = yup.object({
  country,
  state,
  city,
  postalCode,
  address
})

export const CreateUserSchema = yup.object({
  firstName: firstName.required(i18n.t('ErrorMessages.firstName.required')),
  lastName: lastName.required(i18n.t('ErrorMessages.lastName.required')),
  email: email.required(i18n.t('ErrorMessages.email.required')),
  phone: phone.required(i18n.t('ErrorMessages.phone.required'))
})

export const CreateBrokerSchema = yup.object({
  firstName: firstName.required(i18n.t('ErrorMessages.firstName.required')),
  lastName: lastName.required(i18n.t('ErrorMessages.lastName.required')),
  email: email.required(i18n.t('ErrorMessages.email.required')),
  role: role
})

export const CreateUserServerSchema = yup.object({
  firstName: firstName.required(i18n.t('ErrorMessages.firstName.required')),
  lastName: lastName.required(i18n.t('ErrorMessages.lastName.required')),
  email: email.required(i18n.t('ErrorMessages.email.required')),
  phone: phoneCountryCode.required(i18n.t('ErrorMessages.phone.required'))
})

export const UpdateAreaCodeSchema = yup.object({
  areaCode: areaCode.test({
    name: 'test-area-code-match-phone',
    test: (value, testContext) => {
      const phoneNumber = testContext.parent.phone
      if ((!phoneNumber && value) || (phoneNumber && !value)) {
        return testContext.createError({ message: 'Area code and phone field should be sent together' })
      }
      if (!phoneNumber) return true

      const match = phoneNumber?.slice(2, 8).includes(value)
      if (match) return true

      return testContext.createError({ message: 'Area code does not match with phone number' })
    }
  })
})

export const AreaCodeSchema = yup.object({
  areaCode: areaCode.test({
    name: 'test-area-code-match-phone',
    test: (value, testContext) => {
      const phoneNumber = testContext.parent.phone
      const match = phoneNumber?.slice(2, 8).includes(value)
      if (match) return true
      return testContext.createError({ message: 'Area code does not match with phone number' })
    }
  }).required(i18n.t('ErrorMessages.areaCode.required'))
})

export const CreateFarmerServerSchema = CreateUserServerSchema.concat(AreaCodeSchema)

export const SignUpFarmerSchema = yup.object({
  firstName: firstName.required(i18n.t('ErrorMessages.firstName.required')),
  lastName: lastName.required(i18n.t('ErrorMessages.lastName.required')),
  email: email.required(i18n.t('ErrorMessages.email.required')),
  areaCode: areaCode.required(i18n.t('ErrorMessages.areaCode.required'))
    .test('verified', i18n.t('SignUpFarmer.form.areaCodeError'), async (value, values) => {
      const verified = await verifyAreaCode(value as string, values)
      return verified as boolean
    }),
  phone: phoneFarmer.required(i18n.t('ErrorMessages.phoneFarmer.required')),
  termsAndConditions: yup.boolean().isTrue(i18n.t('ErrorMessages.termsAndConditions.isTrue'))
})

export const UpdateFarmerSchema = yup.object({
  firstName: firstName.required(i18n.t('ErrorMessages.firstName.required')),
  lastName: lastName.required(i18n.t('ErrorMessages.lastName.required')),
  email: email.required(i18n.t('ErrorMessages.email.required')),
  areaCode: areaCode.required(i18n.t('ErrorMessages.areaCode.required'))
    .test('verified', i18n.t('SignUpFarmer.form.areaCodeError'), async (value, values) => {
      const verified = await verifyAreaCode(value as string, values)
      return verified as boolean
    }),
  phone: phoneFarmer.required(i18n.t('ErrorMessages.phoneFarmer.required'))
})

export const ConfirmPhoneSchema = yup.object({
  areaCode: areaCode.required(i18n.t('ErrorMessages.areaCode.required'))
    .test('verified', i18n.t('SignUpFarmer.form.areaCodeError'), async (value, values) => {
      const verified = await verifyAreaCode(value as string, values)
      return verified as boolean
    }),
  phone: phoneFarmer.required(i18n.t('ErrorMessages.phoneFarmer.required'))
})

export const UpdateUserSchema = yup.object({
  firstName,
  lastName,
  email
})

export const UpdateUserServerSchema = yup.object({
  firstName,
  lastName,
  email,
  phone: phoneCountryCode
})

export const UpdateFarmerServerSchema = UpdateUserServerSchema.concat(UpdateAreaCodeSchema)

export const merge = (...schemas: yup.AnyObjectSchema[]) => {
  const [first, ...rest] = schemas
  return rest.reduce(
    (mergedSchemas, schema) => mergedSchemas.concat(schema),
    first
  )
}
