import { theme } from '@hedgit/lib/theme'
import styled from 'styled-components/native'
import { View, Text, Divider, Image } from 'native-base'

import H2 from '@hedgit/lib/components/typography/h2'

interface TitleTextProps {
  selected: boolean;
}

const textColor = 'rgba(33, 15, 11, 0.5)'

const { colors } = theme

export const Content = styled(View).attrs({
  shadow: 4
})`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 70px;
  width: 100%;
  background-color: ${colors.white};
  margin-bottom: 16px;
  padding: 0 16px;
`

export const SubContentLeft = styled(View)`
  flex-direction: row;
`

export const SubContentRight = styled(View)`
  flex-direction: row;
  align-items: center;
`

export const NavBarLogoImage = styled(Image).attrs({
  alt: 'InstaSeat Logo',
  size: 30,
  resizeMode: 'contain',
  source: require('assets/images/hedgit-icon.png')
})``

export const AdminContent = styled(Text)`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  width:140px;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  color: rgba(33, 15, 11, 0.5);
`

export const StyledDivider = styled(Divider).attrs({
  w: 70,
  bgColor: colors.black,
  opacity: 0.3,
  alignSelf: 'center'
})``

export const TitleText = styled(H2)<TitleTextProps>`
  margin-bottom: 16px;
  color: ${props => props.selected ? colors.primary : textColor};
`

export const TitleUnderline = styled(Divider).attrs({
  h: '4px',
  rounded: 'full',
  w: '80px'
})``

export const DownloadButton = styled.TouchableOpacity`
  margin-right: 8px;
  margin-bottom: 2px;
  padding: 2px 4px;
  border-color: ${props => props.theme.colors.primary};
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  text-decoration: none;
  text-align: center;
  color: ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.white};
`
export const Pressable = styled.Pressable`
  margin-right: 16px;
`
