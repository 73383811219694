import { action } from 'typesafe-actions'

import { PlanTypeActionConsts } from './types'

import { PlanType } from '../../../interfaces/plan-type'

export const getPlanTypesPending = () =>
  action(PlanTypeActionConsts.GET_PLAN_TYPES_PENDING)

export const getPlanTypesFulfilled = (planTypes: PlanType[]) =>
  action(PlanTypeActionConsts.GET_PLAN_TYPES_FULFILLED, planTypes)

export const getPlanTypesFailed = (error: string) =>
  action(PlanTypeActionConsts.GET_PLAN_TYPES_FAILED, error)
