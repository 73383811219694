/* eslint-disable max-len */

import React from 'react'
import { Svg, Path } from 'react-native-svg'

interface Props {
  color?: string;
  style?: Record<string, unknown>;
}

const UpgradeIcon = ({ style, color }: Props) => {
  return (
    <Svg
      // xmlns="http://www.w3.org/2000/svg"
      // xmlSpace="preserve"
      width={200}
      height={200}
      fill={color}
      viewBox="0 0 24 24"
      style={style}
    >
      <Path d="M12 24C5.4 24 0 18.6 0 12S5.4 0 12 0s12 5.4 12 12-5.4 12-12 12zm0-22C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm1 16h-2V9.4l-3.3 3.3-1.4-1.4L12 5.6l5.7 5.7-1.4 1.4L13 9.4V18z" />
    </Svg>
  )
}

export default UpgradeIcon
