import React, { useEffect } from 'react'
import { View, SafeAreaView } from 'react-native'

import { getUserData } from '@hedgit/lib/store/modules/auth/thunks'

import { useSelector, RootState, useDispatch } from 'store'

import useAuthToken from 'hooks/use-auth-token'

import { AppNavigator } from './app'
import { AuthNavigator } from './auth'

const Navigator = () => {
  const dispatch = useDispatch()
  const token = useSelector((store: RootState) => store.auth.token)
  const isSignedIn = token

  useAuthToken()

  useEffect(() => {
    if (token) {
      dispatch(getUserData())
    }
  }, [token, dispatch])

  return (
    <View
      style={{ height: '100vh', overflow: 'hidden', display: 'flex', maxHeight: '100vh' }}
    >
      <SafeAreaView style={{ flex: 1 }}>
        {isSignedIn ? <AppNavigator /> : <AuthNavigator />}
      </SafeAreaView>
    </View>
  )
}

export default Navigator
