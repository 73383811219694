import { APIResponse } from '@hedgit/lib/types/api'
import { ThunkAction } from '@hedgit/lib/types/redux-thunk'

import { get } from '@hedgit/lib/utils/axios'

import { Product } from '@hedgit/lib/interfaces/product'

import { getProductsPending, getProductsFulfilled, getProductsFailed } from './actions'
import { ProductsActionTypes } from './types'

interface Response {
  products: Product[];
}

export const getProducts = (): ThunkAction<void, unknown, unknown, ProductsActionTypes> => {
  return async (dispatch) => {
    dispatch(getProductsPending())
    try {
      const response = await get<APIResponse<Response>>('/products')
      dispatch(getProductsFulfilled(response.data.products))
    } catch (error) {
      dispatch(getProductsFailed(error))
    }
  }
}
