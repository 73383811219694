import styled from 'styled-components/native'

import { theme } from '../../../theme'

export const AreaCode = styled.View`
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 90px;
  height: 38px;
  margin: 5px;
  border-radius: 15px;
  background-color: ${theme.colors.white};
  box-shadow: 0px 5px 5px rgba(0.5, 0, 0, 0.1);
  border: 1px solid ${theme.colors.primary};
`

export const AreaCodeText = styled.Text`
  font-size: 16px;
  color: ${props => props.theme.colors.primary};
`

export const DeleteButton = styled.TouchableOpacity`
  align-items: center;
`
