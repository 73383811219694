import React, { useEffect } from 'react'
import { View } from 'react-native'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'

// import { theme } from '@hedgit/lib/theme'
import { getPlanTypes } from '@hedgit/lib/store/modules/plan-types/thunks'

import { useDispatch } from 'store'

import { MarketData } from 'routes/market-data'
import { BrokersTable } from 'routes/brokers/table'
import { Signals } from 'routes/signals'
import { BrokersForm } from 'routes/brokers/form'
import { NavBar } from 'routes/navbar'
import { AreaCodes } from 'routes/area-codes'
import { AssignAreaCode } from 'routes/assign-area-codes'

// import { SideBar } from '../sidebar'

const Stack = createStackNavigator()

// const { colors } = theme

const HeaderComponent = () => {
  return (
    <View>
      <NavBar />
    </View>
  )
}

const BrokersNavigator = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false
      }}
    >
      <Stack.Screen
        name="BrokersTable"
        component={BrokersTable}
      />
      <Stack.Screen
        name="BrokersForm"
        component={BrokersForm}
      />
    </Stack.Navigator>
  )
}

const AppNavigator = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPlanTypes())
  }, [dispatch])
  return (
    <View style={{ maxHeight: '100vh', height: '100vh' }}>
      <NavigationContainer>
        <Stack.Navigator
          screenOptions={{
            header: HeaderComponent,
            cardStyle: { maxHeight: '100%' }
          }}
        >
          <Stack.Screen
            name="Brokers"
            component={BrokersNavigator}
          />
          <Stack.Screen name="MarketData" component={MarketData} />
          <Stack.Screen name="Signals" component={Signals} />
          <Stack.Screen name="AreaCodes" component={AreaCodes} />
          <Stack.Screen name="AssignAreaCode" component={AssignAreaCode} />
        </Stack.Navigator>
      </NavigationContainer>
    </View>
  )
}

export default AppNavigator
