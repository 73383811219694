import styled from 'styled-components/native'

import Caption from '@hedgit/lib/components/typography/caption'
import { Background as BackgroundSvg } from '@hedgit/lib/components/background'
import Body from '@hedgit/lib/components/typography/body'
import Title from '@hedgit/lib/components/typography/title'

export const Content = styled.View`
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.colors.whiteMiddle};
  overflow-y: scroll;
`

export const SubContent = styled.View`
  padding: 16px;
  align-items: center;
  background-color: ${props => props.theme.colors.whiteMiddle};
`

export const Container = styled.View`
  align-items: center;
  align-self: center;
  padding: 16px;
  padding-top: 0;
  border-radius: 10px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000021;
  opacity: 1;
  width: 1024px;
  max-width: 1024px;
`

export const HeaderContent = styled.View`
  width: 1024px;
  padding: 0 8px 16px 8px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Header = styled.View`
  z-index: 10;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 64px;
  margin-bottom: 24px;
`

export const BackButtonContainer = styled.View`
  position: absolute;
  left: -16px;
`

export const UserIcon = styled.View`
  position: absolute;
  background-color: #33A3D9;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: -50px;
  box-shadow: 0 16px 40px #00000029;
  padding-bottom: 6px;
`

export const UserText = styled.Text`
  font-size: 46px;
  color: #FFFFFF;
`

export const StyledBackground = styled(BackgroundSvg)`
  position: absolute;
  height: 110vh;
  left: -100vw;
  top: -20vh;
`

export const InputsContainer = styled.View`
  flex-direction: row;
  width: 100%;
  gap: 10px;
  padding-top: 16px;
`

export const Requirements = styled(Caption)`
  margin-top: 8px;
  margin-left: 8px;
  color: ${props => props.theme.colors.gray};
`

export const ButtonContainer = styled.View`
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
`

export const Error = styled(Body)`
  color: ${props => props.theme.colors.red};
  text-align: center;
  margin-top: 8px;
`

export const FieldContainer = styled.View`
  height: 96px;
`
export const UpgradeButton = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const InputContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const AreaCodeTitle = styled(Title)`
  margin-left: 5px;
`

export const CardContent = styled.View`
  height: 130px;
  width: 420px;
  margin: 10px auto 0;
  border-radius: 20px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
  background-color: ${props => props.theme.colors.white};
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
`

export const AreaCodeContent = styled.View`
  align-items: center;
  justify-content: center;
  padding: 5px;
  flex-wrap: wrap;
  flex-direction: row;
`
export const PlanInformation = styled.Text`
  padding-top: 10px;
  padding-left: 5px;
  color: ${props => props.theme.colors.gray};
`
export const EmptyAreaCodes = styled.Text`
  text-align: center;
  font-family: Lato;
`
