import React, { useEffect, useState } from 'react'
import { View, Pressable, ActivityIndicator } from 'react-native'
import { useTranslation } from 'react-i18next'
import { theme } from '@hedgit/lib/theme'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { useSelector, useThunkDispatch } from '@hedgit/admin/src/store'
import Select from 'react-select'
import { useToast } from 'react-native-toast-notifications'

import { getBrokers } from '@hedgit/lib/store/modules/brokers/thunks'
import { assignAreaCodes } from '@hedgit/lib/store/modules/area-codes'

import { PlanName } from '@hedgit/lib/enums/plan-name'
import { Status } from '@hedgit/lib/enums/status'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'

import H1 from '@hedgit/lib/components/typography/h1'
import GoBackIcon from '@hedgit/lib/components/icons/go-back'
import H2 from '@hedgit/lib/components/typography/h2'
import { AreaCodeCard } from '@hedgit/lib/components/cards/area-code-card/area-code-card'
import { Modal } from '@hedgit/lib/components/modals/basic-modal'

import {
  Container,
  HeaderContent,
  Content,
  SubContent,
  SearchComponent,
  ContentSearch,
  SaveButton,
  TextContainer,
  Image,
  ImageContainer,
  SelectContainer,
  AreaCodeCardComponent,
  CardContent,
  AreaCodeContent,
  ContentCardArea,
  Box
} from './styled'

type Props = NativeStackScreenProps<RootStackParamList, 'AssignAreaCode'>

const { colors } = theme

const AssignAreaCode = ({ route, navigation }: Props) => {
  const { t } = useTranslation()
  const dispatch = useThunkDispatch()
  const brokers = useSelector(state => state.brokers.brokerList)
  const isLoading = useSelector(state => state.areaCodes.isFetching)
  const brokersWithXpPremiumPlan = brokers.filter((broker) =>
    broker.status === Status.active && broker.subscriptionPlan?.planType.name === PlanName.xpPremium
  )
  const toast = useToast()

  const [areaCodes, setAreaCodes] = useState(route.params.areaCodes)
  const [showModal, setShowModal] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [broker, setBroker] = useState('')

  const removeAreaCode = (code: string) => {
    if (areaCodes.length <= 1) return
    setAreaCodes((prevAreaCodes) =>
      prevAreaCodes.filter((c) => c.prefix.toString() !== code)
    )
  }

  const isDisabled = isSaving || !broker || !areaCodes.length

  const handleOnPress = () => {
    setShowModal(true)
  }

  const handleOnSave = async () => {
    const areaCodesId = areaCodes.map((code) => code.id)
    try {
      setIsSaving(true)
      const response = await dispatch(assignAreaCodes(areaCodesId, broker))
      if (response.success === false) throw new Error(response.errorMsg)
      toast.show(t('Admin.areaCodes.toast.success'), {
        type: 'success',
        duration: 4000,
        placement: 'bottom',
        animationType: 'zoom-in'
      })
      navigation.goBack()
    } catch (error) {
      toast.show(t('Admin.areaCodes.toast.error'), {
        type: 'danger',
        duration: 4000,
        placement: 'bottom',
        animationType: 'zoom-in'
      })
    }
    setIsSaving(false)
  }

  const handleGoBack = () => {
    setShowModal(false)
    navigation.goBack()
  }

  useEffect(() => {
    dispatch(getBrokers())
  }, [dispatch])

  return (
    <Content>
      <SubContent>
        <HeaderContent>
          <Pressable testID='nav-bar-menu' onPress={handleOnPress}>
            <GoBackIcon color={colors.dark} />
          </Pressable>
          <H1>{t('Brokers.assignAreaCode.title')}</H1>
          <View />
        </HeaderContent>
        <Box>
          <Container>
            <AreaCodeCardComponent>
              <ContentCardArea>
                <H2>{t('Components.card.assignAreaCode.title')}</H2>
                <CardContent>
                  <AreaCodeContent>
                    {areaCodes.map((code) => (
                      <AreaCodeCard
                        key={code.id}
                        value={code.prefix.toString()}
                        hasDelete={areaCodes.length > 1}
                        onPress={() => removeAreaCode(code.prefix.toString())}
                        disabled={isLoading}
                      />
                    ))}
                  </AreaCodeContent>
                </CardContent>
              </ContentCardArea>
            </AreaCodeCardComponent>
            <SearchComponent>
              <ContentSearch>
                <H2>{t('Brokers.assignAreaCode.searchTitle')}</H2>
                <SelectContainer>
                  <Select
                    options={brokersWithXpPremiumPlan.map(broker => ({
                      value: broker.id,
                      label: `${broker.firstName} ${broker.lastName}`
                    }))}
                    onChange={value => value && setBroker(value.value)}
                    isDisabled={isLoading}
                  />
                </SelectContainer>
              </ContentSearch>
            </SearchComponent>
          </Container>
          <SaveButton testID="area-code-save-button" onPress={handleOnSave} disabled={isDisabled}>
            <TextContainer>
              { isSaving
                ? (
                  <ActivityIndicator color={colors.white} />
                  )
                : t('Components.button.save')}
            </TextContainer>
            <ImageContainer>
              <Image source={require('@hedgit/lib/components/sidebar/assets/icons/hedgit-icon-sm.png')} />
            </ImageContainer>
          </SaveButton>
        </Box>
        <Modal
          title={t('Components.modal.areaCodes.title')}
          variant="confirm"
          visible={showModal}
          buttons={[
            {
              label: t('Components.modal.button.stay'),
              onPress: () => setShowModal(false),
              testID: 'stay-modal-button',
              variant: 'secondary'
            },
            {
              label: t('Components.modal.button.goBack'),
              onPress: handleGoBack,
              testID: 'go-back-modal-button',
              variant: 'primary'
            }
          ]}
        />
      </SubContent>
    </Content>
  )
}

export default AssignAreaCode
