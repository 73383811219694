import { Reducer } from 'redux'

import { PlanTypeActionConsts, PlanTypesState, PlanTypesActionTypes } from './types'

export const initialState: PlanTypesState = {
  list: [],
  isFetching: false,
  error: ''
}

export const planTypeReducer: Reducer<PlanTypesState,
PlanTypesActionTypes> = (
  state = initialState,
  action
): PlanTypesState => {
  switch (action.type) {
    case PlanTypeActionConsts.GET_PLAN_TYPES_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case PlanTypeActionConsts.GET_PLAN_TYPES_FULFILLED:
      return {
        ...state,
        isFetching: false,
        list: action.payload
      }
    case PlanTypeActionConsts.GET_PLAN_TYPES_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }
    default:
      return state
  }
}
