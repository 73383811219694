import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFocusEffect } from '@react-navigation/native'
import { ActivityIndicator } from 'react-native'
import { useSelector, useThunkDispatch } from '@hedgit/admin/src/store'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { theme } from '@hedgit/lib/theme'
import {
  ColumnDef,
  ColumnFiltersState,
  Row,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  useReactTable
} from '@tanstack/react-table'
import { NativeStackScreenProps } from '@react-navigation/native-stack'

import { getAreaCodes, removeAreaCodes } from '@hedgit/lib/store/modules/area-codes'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'

import H1 from '@hedgit/lib/components/typography/h1'
import ArrowTwoIcon from '@hedgit/lib/components/icons/arrow-two'
import ArrowTwoLeftIcon from '@hedgit/lib/components/icons/arrow-two-left'
import { DeleteAreaCodesModal } from '@hedgit/lib/components/modals/delete-area-codes-modal'

import useCustomToast from 'hooks/use-custom-toast'

import { AreaCodeData, SelectCell, ActionCell, PAG_SIZE_DEFAULT_VALUE } from './constants'
import {
  AssignButton,
  Content,
  HeaderContent,
  SubContent,
  ButtonText,
  TableContent,
  PaginationContent,
  PaginationButton,
  ContentText,
  ItemsContainer,
  AreaCodeTable,
  Td,
  Th,
  Tr,
  TablePaginationWrapper,
  Thead,
  SearchContent,
  SearchAndButtonContent,
  TextNotFound
} from './styled'
import { DebouncedInput } from './debounced-input'

const { colors } = theme

type Props = NativeStackScreenProps<RootStackParamList, 'AreaCodes'>

const AreaCodes = ({ navigation }: Props) => {
  const { t } = useTranslation()
  const dispatch = useThunkDispatch()
  const { showToast } = useCustomToast()
  const areaCodes = useSelector(store => store.areaCodes.list)

  const areaCodeData = useMemo(() => {
    const areaCodesSort = areaCodes.sort((a, b) => a.province.localeCompare(b.province))
    return areaCodesSort.map(areaCode => ({
      ...areaCode,
      brokerName: areaCode.broker
        ? `${areaCode.broker?.firstName} ${areaCode.broker?.lastName}`
        : t('Admin.areaCodes.unassigned')
    }))
  }, [areaCodes, t])
  const isLoading = useSelector(store => store.areaCodes.isFetching)
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [deleteAreaCodeData, setDeleteAreaCodeData] = useState<{
    brokerId: string; areaCodeId: string[]; brokerName: string; areaCode: number[];}>()

  const [globalFilter, setGlobalFilter] = useState('')

  const onDelete = (row: Row<AreaCodeData>) => {
    if (!row.original.broker || !row.original.brokerName) return
    setDeleteAreaCodeData({
      brokerId: row.original.broker.id,
      brokerName: row.original.brokerName,
      areaCode: [row.original.prefix],
      areaCodeId: [row.original.id]
    })
    setDeleteModalVisible(true)
  }

  const [rowSelection, setRowSelection] = useState({})
  const [unassign, setUnassign] = useState<boolean>(false)
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [pagSizeValue, setPagSizeValue] = useState(PAG_SIZE_DEFAULT_VALUE)
  const [pageIndex, setPageIndex] = useState(0)

  const ActionCellComponent = useCallback(({ row }) => (
    <ActionCell
      row={row}
      onDelete={() => onDelete(row)}
      disabled={Object.keys(rowSelection).length > 0}
    />
  ), [rowSelection])

  const onConfirmDelete = async () => {
    if (!deleteAreaCodeData) return showToast(t('Components.modal.delete.error'), 'danger')
    const { success } = await dispatch(removeAreaCodes(deleteAreaCodeData.areaCodeId, deleteAreaCodeData.brokerId))
    setDeleteModalVisible(false)
    setDeleteAreaCodeData(undefined)
    if (!success) return showToast(t('Components.modal.delete.error'), 'danger')
    showToast(t('Components.modal.delete.success'), 'success')
    table.resetRowSelection()
  }

  const columns = useMemo<ColumnDef<AreaCodeData>[]>(() => {
    return [
      {
        id: 'select',
        cell: SelectCell,
        size: 20,
        maxSize: 20
      },
      {
        accessorKey: 'prefix',
        header: t('Admin.areaCodes.areaCode'),
        size: 80
      },
      {
        accessorKey: 'city',
        header: t('Admin.areaCodes.location')
      },
      {
        accessorKey: 'province',
        header: t('Admin.areaCodes.province'),
        size: 100
      },
      {
        id: 'brokerName',
        accessorKey: 'brokerName',
        header: 'Broker'
      },
      {
        id: 'action',
        cell: ActionCellComponent,
        size: 20,
        maxSize: 20
      }
    ]
  }, [ActionCellComponent, t])

  const table = useReactTable({
    data: areaCodeData,
    columns,
    state: {
      rowSelection,
      columnFilters,
      globalFilter
    },
    enableRowSelection: row => !Object.keys(rowSelection).length ||
      (Object.keys(rowSelection).length > 0 &&
      ((!unassign && !row.original.broker) || (unassign && !!row.original.broker))),
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    debugTable: true,
    onColumnFiltersChange: setColumnFilters,
    globalFilterFn: (row, columnId, filterValue) => {
      const formattedFilterValue = filterValue
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '')
        .toLowerCase()
      const cellValue = row.getValue(columnId)
      if (typeof cellValue !== 'string' && typeof cellValue !== 'number') return false
      const formattedDataValue = cellValue.toString()
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '')
        .toLowerCase()
      return formattedDataValue.includes(formattedFilterValue)
    }
  })

  useFocusEffect(
    useCallback(() => {
      dispatch(getAreaCodes())
      table.resetRowSelection()
    }, [dispatch, table])
  )

  useEffect(() => {
    table.setPageIndex(pageIndex)
  }, [table, pageIndex, areaCodeData])

  useEffect(() => {
    const brokerNameColumn = table.getColumn('brokerName')
    const selectedRows = table.getSelectedRowModel().flatRows
    const haveBroker = selectedRows.every(row => 'broker' in row.original)

    const localUnassign = haveBroker && selectedRows.length > 0

    setUnassign(localUnassign)

    const prevFilter = brokerNameColumn?.getFilterValue()

    const newFilter = selectedRows[0]?.original.brokerName

    if (localUnassign && newFilter !== prevFilter) return brokerNameColumn?.setFilterValue(newFilter)

    if (prevFilter && !newFilter) brokerNameColumn?.setFilterValue(undefined)
  }, [table, rowSelection])

  const handleOnPress = () => {
    const selectedRows = table.getSelectedRowModel().rows.map((row) => row.original)
    setPageIndex(table.getState().pagination.pageIndex)
    if (unassign) {
      setDeleteAreaCodeData({
        brokerId: selectedRows[0].broker?.id || '',
        brokerName: selectedRows[0].brokerName || '',
        areaCode: selectedRows.map(areaCode => areaCode.prefix),
        areaCodeId: selectedRows.map(areaCode => areaCode.id)
      })
      return setDeleteModalVisible(true)
    }
    navigation.navigate('AssignAreaCode', { areaCodes: selectedRows })
  }

  return (
    <Content>
      <SubContent>
        <HeaderContent>
          <H1>{t('Admin.areaCodes.title')}</H1>
          <SearchAndButtonContent>
            <SearchContent>
              <DebouncedInput
                value={globalFilter ?? ''}
                onChange={(value) => setGlobalFilter((String(value)))}
                placeholder={t('Admin.areaCodes.placeholder')}
              />
            </SearchContent>
            <AssignButton
              testID='assign-button'
              onPress={handleOnPress}
              disabled={Object.keys(rowSelection).length === 0}
              color={unassign ? colors.white : colors.primary}
            >
              <ButtonText color={unassign ? colors.primary : colors.white}>
                {unassign ? t('Components.button.unassign') : t('Components.button.assign')}
              </ButtonText>
            </AssignButton>
          </SearchAndButtonContent>
        </HeaderContent>
        {isLoading
          ? (
            <ActivityIndicator color={colors.primary} size='large' />
            )
          : (
              table.getRowModel().rows.length === 0 && (
              <TextNotFound>{t('Admin.areaCodes.notFound')}</TextNotFound>
              )
            )}
        {(!isLoading && table.getRowModel().rows.length > 0) && (
        <TablePaginationWrapper>
          <TableContent>
            <AreaCodeTable>
              <Thead>
                {table.getHeaderGroups().map(headerGroup => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => {
                      return (
                        <Th key={header.id} colSpan={header.colSpan}>
                          {header.isPlaceholder
                            ? null
                            : (
                              <>
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                              </>
                              )}
                        </Th>
                      )
                    })}
                  </Tr>
                ))}
              </Thead>
              <tbody>
                {table.getRowModel().rows.map(row => {
                  return (
                    <Tr key={row.id}>
                      {row.getVisibleCells().map(cell => {
                        return (
                          <Td key={cell.id} style={{ width: cell.column.getSize() }}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Td>
                        )
                      })}
                    </Tr>
                  )
                })}
              </tbody>
            </AreaCodeTable>
          </TableContent>
          <PaginationContent>
            <ItemsContainer>
              <PaginationButton
                onPress={table.previousPage}
                disabled={!table.getCanPreviousPage()}
              >
                <ArrowTwoLeftIcon color={table.getCanPreviousPage() ? colors.black : colors.lightGray} />
              </PaginationButton>
            </ItemsContainer>
            <ItemsContainer>
              <ContentText>
                {table.getState().pagination.pageIndex + 1}
                /
                {table.getPageCount()}
              </ContentText>
            </ItemsContainer>
            <ItemsContainer>
              <PaginationButton
                onPress={table.nextPage}
                disabled={!table.getCanNextPage()}
              >
                <ArrowTwoIcon color={table.getCanNextPage() ? colors.black : colors.lightGray} />
              </PaginationButton>
            </ItemsContainer>
            <ItemsContainer>
              <Select
                options={[10, 20, 30, 40, 50].map(pagSize => ({
                  value: pagSize,
                  label: pagSize
                }))}
                defaultValue={PAG_SIZE_DEFAULT_VALUE}
                isSearchable={false}
                onChange={value => {
                  setPagSizeValue(value ?? PAG_SIZE_DEFAULT_VALUE)
                  table.setPageSize(Number(value?.value))
                }}
                value={pagSizeValue}
                menuPlacement="auto"
              />
            </ItemsContainer>
          </PaginationContent>
        </TablePaginationWrapper>
        )}
      </SubContent>
      <DeleteAreaCodesModal
        areaCodes={deleteAreaCodeData?.areaCode || [0]}
        broker={deleteAreaCodeData?.brokerName}
        visible={deleteModalVisible}
        buttons={[
          {
            label: t('Components.modal.button.cancel'),
            onPress: () => setDeleteModalVisible(false),
            testID: 'delete-modal-button-cancel',
            variant: 'secondary',
            disabled: isLoading
          },
          {
            label: t('Components.modal.button.confirm'),
            onPress: onConfirmDelete,
            testID: 'delete-modal-button-confirm',
            variant: 'primary',
            disabled: isLoading
          }
        ]}
      />
    </Content>
  )
}

export default AreaCodes
