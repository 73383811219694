import { ActionType } from 'typesafe-actions'

import * as actions from './actions'

import { PlanType } from '../../../interfaces/plan-type'
export enum PlanTypeActionConsts {
  GET_PLAN_TYPES_PENDING='GET_PLAN_TYPES_PENDING',
  GET_PLAN_TYPES_FULFILLED='GET_PLAN_TYPES_FULFILLED',
  GET_PLAN_TYPES_FAILED='GET_PLAN_TYPES_FAILED',
}

export type PlanTypesActionTypes = ActionType<typeof actions>

export interface PlanTypesState {
  readonly list: PlanType[];
  readonly isFetching: boolean;
  readonly error: string;
}
