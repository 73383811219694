import styled from 'styled-components/native'

import Body from '@hedgit/lib/components/typography/body'
import H2 from '@hedgit/lib/components/typography/h2'

export const Content = styled.View`
  padding: 16px;
  align-items: center;
  /* align-items: center; */
  background-color: ${props => props.theme.colors.whiteMiddle};
`

export const SubContent = styled.View`
  flex-direction: row;
  justify-content: center;
  height: 85vh;
  padding: 16px;
  border-radius: 10px;
  gap: 16px;
  min-width: 992px;
  max-width: 1600px;
  background-color: ${props => props.theme.colors.white};
`

export const FormContent = styled.View`
  flex: 1;
  width: 100%;
`

export const TableContent = styled.View`
  flex: 2;
`

export const FieldContainer = styled.View`
  width: 300px;
  height: 96px;
`

export const ButtonContainer = styled.View`
  width: 300px;
  gap: 16px;
`

export const ListContainer = styled.Text`
  padding: 16px;
  border-radius: 10px;
  height: 75vh;
  border: 1px ${props => props.theme.colors.gray} solid;
  box-shadow: 0 3px 6px #0000001A;
  overflow: hidden;
`

export const Error = styled(Body)`
  color: ${props => props.theme.colors.red};
  text-align: center;
  margin-top: 8px;
`

export const ProductTitle = styled(H2)`
  margin-bottom: 32px;
  margin-left: 8px;
`

export const ValueInput = styled.View`
  width: 100%;
`
