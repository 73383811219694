import styled from 'styled-components/native'
import webStyled from 'styled-components'

import Button from '@hedgit/lib/components/typography/button-text'
import H2 from '@hedgit/lib/components/typography/h2'

interface ColorProps {
  color: string;
}

export const Content = styled.View`
  justify-content: center;
  align-items: center;
  max-height: 100%;
  width: 100%;
  background-color: ${props => props.theme.colors.whiteMiddle};
`

export const SubContent = styled.View`
  padding: 16px;
  height: 100%;
  background-color: ${props => props.theme.colors.whiteMiddle};
`

export const HeaderContent = styled.View`
  width: 1000px;
  padding: 0 8px 16px 8px;
  flex-direction: row;
  justify-content: space-between;
`

export const AssignButton = styled.TouchableOpacity<ColorProps>`
  justify-content: center;
  align-items: center;
  min-width: 72px;
  height: 32px;
  border-radius: 10px;
  border: 1px solid;
  background-color: ${props => props.color};
  border-color: ${props => props.theme.colors.primary};
  opacity: ${props => props.disabled ? 0.5 : 1};
`

export const ButtonText = styled(Button)<ColorProps>`
  color: ${props => props.color};
  padding: 5px;
`

export const TablePaginationWrapper = styled.View`
  flex: 1;
  flex-basis: 0%;
`

export const TableContent = styled.View`
  border-radius: 10px;
  flex: 1;
  flex-basis: 100%;
  overflow: scroll;
`

export const PaginationContent = styled.View`
  flex-direction: row;
  margin-top: 10px;
`

export const PaginationButton = styled.TouchableOpacity`
  flex-direction: row;
  margin-right: 5px;
`

export const ItemsContainer = styled.View`
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`

export const ContentText = styled.Text`
  font-size: 18px;
  font-family: Lato;
  font-weight: 400;
`

export const IconButton = styled.TouchableOpacity`
  align-items: center;
  position: initial;
`

export const CheckboxWrapper = webStyled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const AreaCodeTable = webStyled.table`
  justify-content: baseline;
  align-items: center;
  width: 1000px;
  max-width: 1000px;
  padding: 16px;
  padding-top: 0;
  box-shadow: 0 3px 6px #0000001A;
  border-radius: 10px;
  font-family: Lato;
  color: #313C4D;
  background-color: white;
  overflow: scroll;
`

export const Thead = webStyled.thead`
  position: sticky;
  top: 0;
  background-color: white;
`

export const Th = webStyled.th`
  font-size: 18px;
  padding: 8px;
  padding-top: 16px;
  text-align: left;
  border-bottom: 1px solid #107DB2;
`

export const Td = webStyled.td`
  font-size: 14px;
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #107DB2;
`

export const Tr = webStyled.tr`
`

export const TdIcon = webStyled(Td)`
  flex-direction: row;
  justify-content: center;
`

export const SearchContent = styled.View`
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  padding: 10px;
  border-radius: 15px;
`

export const SearchAndButtonContent = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`

export const TextNotFound = styled(H2)`
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-left: 8px;
`

export const Input = webStyled.input`
  padding: 8px;
  border: 1px solid gray;
  border-radius: 12px;
  outline: none;
`
