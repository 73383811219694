import React from 'react'
import { GestureResponderEvent } from 'react-native'
import { theme } from '@hedgit/lib/theme'

import ErrorIcon from '@hedgit/lib/components/icons/error'

import { AreaCode, AreaCodeText, DeleteButton } from './styled'

const { colors } = theme

interface CodeAreaCardProps {
  value: string;
  onPress?: (event: GestureResponderEvent) => void;
  hasDelete?: boolean;
  disabled?: boolean;
}

export const AreaCodeCard = ({ value, onPress, hasDelete, disabled }: CodeAreaCardProps) => {
  return (
    <AreaCode testID="code-card-container">
      <AreaCodeText>{value}</AreaCodeText>
      { hasDelete && (
        <DeleteButton
          onPress={onPress}
          testID="delete-area-code-button"
          disabled={disabled}
        >
          <ErrorIcon width={20} height={20} color={colors.red} />
        </DeleteButton>
      )}
    </AreaCode>
  )
}
