import { ActionType } from 'typesafe-actions'

import { AreaCode } from '@hedgit/lib/interfaces/area-code'

import * as actions from './actions'

export enum AreaCodesActionConsts {
  GET_AREA_CODES_PENDING='GET_AREA_CODES_PENDING',
  GET_AREA_CODES_FULFILLED='GET_AREA_CODES_FULFILLED',
  GET_AREA_CODES_FAILED='GET_AREA_CODES_FAILED',
  GET_AREA_CODES_BY_BROKER_PENDING='GET_AREA_CODES_BY_BROKER_PENDING',
  GET_AREA_CODES_BY_BROKER_FULFILLED='GET_AREA_CODES_BY_BROKER_FULFILLED',
  GET_AREA_CODES_BY_BROKER_FAILED='GET_AREA_CODES_BY_BROKER_FAILED',
  ASSIGN_AREA_CODES_PENDING='ASSIGN_AREA_CODES_PENDING',
  ASSIGN_AREA_CODES_FULFILLED='ASSIGN_AREA_CODES_FULFILLED',
  ASSIGN_AREA_CODES_FAILED='ASSIGN_AREA_CODES_FAILED',
  REMOVE_AREA_CODES_PENDING='REMOVE_AREA_CODES_PENDING',
  REMOVE_AREA_CODES_FULFILLED='REMOVE_AREA_CODES_FULFILLED',
  REMOVE_AREA_CODES_FAILED='REMOVE_AREA_CODES_FAILED',
}

export type AreaCodesActionTypes = ActionType<typeof actions>

export interface AreaCodesState {
  readonly list: AreaCode[];
  readonly isFetching: boolean;
  readonly error: string;
}
