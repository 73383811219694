import styled from 'styled-components/native'

import Subtitle from '@hedgit/lib/components/typography/subtitle'
import ButtonText from '@hedgit/lib/components/typography/button-text'

export const Content = styled.View`
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.colors.whiteMiddle};
  overflow-y: scroll;
`

export const SubContent = styled.View`
  padding: 16px;
  align-items: center;
  background-color: ${props => props.theme.colors.whiteMiddle};
`

export const HeaderContent = styled.View`
  width: 1024px;
  padding: 0 8px 16px 8px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Header = styled.View`
  z-index: 10;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 64px;
  margin-bottom: 24px;
`

export const BackButtonContainer = styled.View`
  position: absolute;
  left: -16px;
`

export const Container = styled.View`
  align-items: center;
  align-self: center;
  padding: 16px;
  opacity: 1;
  width: 1024px;
  max-width: 1024px;
  flex-direction: row;
  justify-content: space-evenly;
`

export const SearchComponent = styled.View`
  border-radius: 10px;
  width: 48%;
  padding: 30px;
  min-height: 450px;
  justify-content: space-between;
`

export const ContentSearch = styled.View`
  flex-direction: column;
  justify-content: space-between;
  background-color: ${props => props.theme.colors.white};
  width: 100%;
  height: 500%;
  padding: 10px 40px 15px 40px;
  align-items: center;
`

export const TextContainer = styled(ButtonText)`
  width: 100%;
  text-align: center;
  color: ${props => props.theme.colors.white};
`

export const SaveButton = styled.TouchableOpacity`
  background-color: ${props => props.theme.colors.lightBlue};
  opacity: ${props => props.disabled ? 0.5 : 1};
  height: 52px;
  border-radius: 8px;
  flex-direction: row-reverse;
  align-items: center;
  align-self:center;
  width: 30%;
  margin-bottom: 30px;
`
export const Image = styled.Image`
  width: 82px;
  height: 82px;
  margin-right: -15px;
`

export const ImageContainer = styled.View`
  align-content: center;
  position: absolute;
  margin-left: 10px;
`

export const SelectContainer = styled.View`
  margin-top: 40px;
  width: 80%;
`

export const AreaCodeCardComponent = styled(Subtitle)`
  border-radius: 10px;
  width: 48%;
  padding: 30px;
  min-height: 450px;
  padding-top: 30px;
`

export const ContentCardArea = styled.View`
  flex-direction: column;
  background-color: ${props => props.theme.colors.white};
  width: 100%;
  height: 500%;
  align-items: center;
  justify-content: center;
  padding: 10px;
`

export const CardContent = styled.View`
  border-radius: 20px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
  margin-top: 40px;
  background-color: ${props => props.theme.colors.white};
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
`

export const AreaCodeContent = styled.View`
  align-items: center;
  justify-content: center;
  padding: 5px;
  flex-wrap: wrap;
  flex-direction: row;
`

export const Box = styled.View`
  flex-direction: column;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000021;
  border-radius: 10px;
`
