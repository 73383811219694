import { ActionType } from 'typesafe-actions'

import { BrokerWithSubscriptionPlan } from '@hedgit/lib/interfaces/broker'

import * as actions from './actions'

export enum BrokersActionConsts {
  GET_BROKERS_PENDING='GET_BROKERS_PENDING',
  GET_BROKERS_FULFILLED='GET_BROKERS_FULFILLED',
  GET_BROKERS_FAILED='GET_BROKERS_FAILED',
}

export type BrokersActionTypes = ActionType<typeof actions>

export interface BrokersState {
  readonly brokerList: BrokerWithSubscriptionPlan[];
  readonly isFetching: boolean;
  readonly error: string;
}
