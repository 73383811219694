import axios, { AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse, Method } from 'axios'

const url = `${process.env.API_URL}${process.env.ENV_NAME === 'local' ? `:${process.env.API_PORT}` : ''}/api`

/**
 * For adding Authorization header to all requests it's recommended to use an axios interceptor like this:
 *
 * axios.interceptors.request.use((config) => {
 *   if (config.headers) {
 *     config.headers.Authorization = `Bearer ${token}`
 *   }
 *   return config
 * })
 */
const getClient = async (headers: AxiosRequestHeaders = {}, timeout = 30000): Promise<AxiosRequestConfig> => {
  return {
    baseURL: url,
    timeout,
    headers: {
      'Content-Type': 'application/json',
      ...headers
    }
  }
}

const parseResponse = (response: AxiosResponse) => {
  let error = false
  if (response.status < 200 || response.status > 299) {
    error = true
  }
  if (error) {
    const parsedResponse = {
      ...response.data,
      message: response.data.subCode
        ? response.data.subCode
        : response.data.message
    }
    return Promise.reject(parsedResponse)
  }
  return response.data
}

export const request = async <T>(
  method: Method,
  endpoint: string,
  data: object = {},
  headers?: AxiosRequestHeaders,
  timeout?: number
): Promise<T> => {
  const client = await getClient(headers, timeout)
  return axios(endpoint, {
    ...client,
    method,
    data: JSON.stringify(data)
  }).then(parseResponse)
}

export const get = async <T>(
  endpoint: string,
  signal?: AbortSignal,
  headers?: AxiosRequestHeaders,
  timeout?: number
): Promise<T> => {
  return request('GET', endpoint, signal, headers, timeout)
}

export const post = async <T>(
  endpoint: string,
  data: object = {},
  headers?: AxiosRequestHeaders,
  timeout?: number
): Promise<T> => {
  return request('POST', endpoint, data, headers, timeout)
}

export const patch = async <T>(
  endpoint: string,
  data: object = {},
  headers?: AxiosRequestHeaders
): Promise<T> => {
  return request('PATCH', endpoint, data, headers)
}

export const put = async <T>(
  endpoint: string,
  data: object = {},
  headers?: AxiosRequestHeaders
): Promise<T> => {
  return request('PUT', endpoint, data, headers)
}

export const del = async <T>(endpoint: string, headers?: AxiosRequestHeaders): Promise<T> => {
  return request('DELETE', endpoint, {}, headers)
}

export const mock = (data: object, delay = 1000) =>
  new Promise(resolve =>
    setTimeout(
      () =>
        resolve({
          statusCode: 200,
          headers: {},
          data: data
        }),
      delay
    )
  )
