import { Dispatch } from 'redux'
import { ThunkAction } from '@hedgit/lib/types/redux-thunk'
import { APIResponse } from '@hedgit/lib/types/api'
import { getAuth } from '@firebase/auth'
import AsyncStorage from '@react-native-async-storage/async-storage'

import { get, post, patch } from '@hedgit/lib/utils/axios'

import { User } from '@hedgit/lib/interfaces/user'
import { Farmer } from '@hedgit/lib/interfaces/farmer'
import { Broker } from '@hedgit/lib/interfaces/broker'
import { AuthUser } from '@hedgit/lib/interfaces/auth-user'

import { AuthActionTypes } from './types'
import {
  verifyUserPending,
  verifyUserFulfilled,
  verifyUserRejected,
  createFarmerPending,
  createFarmerFulfilled,
  createFarmerRejected,
  createBrokerPending,
  createBrokerFulfilled,
  createBrokerRejected,
  getUserDataPending,
  getUserDataFulfilled,
  getUserDataRejected,
  signOut,
  updateFarmerPending,
  updateFarmerFulfilled,
  updateFarmerRejected,
  updateBrokerPending,
  updateBrokerFulfilled,
  updateBrokerRejected,
  deleteBrokerPending,
  deleteBrokerFulfilled,
  deleteBrokerRejected,
  suspendBrokerPending,
  suspendBrokerFulfilled,
  suspendBrokerRejected,
  verifyAreaCodePending,
  verifyAreaCodeFulfilled,
  verifyAreaCodeRejected
} from './actions'

export const signOutThunk = (): ThunkAction<void, unknown, unknown, AuthActionTypes> => {
  return async (dispatch: Dispatch) => {
    getAuth().signOut()
    AsyncStorage.removeItem('auth_token')
    dispatch(signOut())
  }
}

interface UserResponse {
  user: User;
}

export const getUserData = (): ThunkAction<void, unknown, unknown, AuthActionTypes> => {
  return async (dispatch: Dispatch) => {
    dispatch(getUserDataPending())
    try {
      const response =
        await get<APIResponse<UserResponse>>('/auth/user')

      if (response.error) throw new Error()
      dispatch(getUserDataFulfilled(response.data.user))
    } catch (error) {
      dispatch(getUserDataRejected(error))
    }
  }
}

interface VerifyUserResponse {
  userExists: boolean;
}

export const verifyUser = (phone: string): ThunkAction<void, unknown, unknown, AuthActionTypes> => {
  return async (dispatch: Dispatch) => {
    dispatch(verifyUserPending())
    try {
      const response = await post<APIResponse<VerifyUserResponse>>('/auth/verify', { phone })
      if (response.error) throw new Error()
      dispatch(verifyUserFulfilled(response.data.userExists))
    } catch (error) {
      dispatch(verifyUserRejected(error))
    }
  }
}

interface CreateFarmerResponse {
  farmer: Farmer;
}

export const createFarmer = (data: AuthUser): ThunkAction<void, unknown, unknown, AuthActionTypes> => {
  return async (dispatch: Dispatch) => {
    dispatch(createFarmerPending())
    try {
      const response =
        await post<APIResponse<CreateFarmerResponse>>('/farmers', data)

      if (response.error) throw new Error()
      dispatch(createFarmerFulfilled(response.data.farmer))
    } catch (error) {
      dispatch(createFarmerRejected(error))
    }
  }
}

interface CreateBrokerResponse {
  broker: Broker;
}

export const createBroker = (data: User): ThunkAction<Promise<string | void>, unknown, unknown, AuthActionTypes> => {
  return async (dispatch: Dispatch) => {
    dispatch(createBrokerPending())
    try {
      const response =
        await post<APIResponse<CreateBrokerResponse>>('/brokers', data)
      if (response.error) throw new Error()
      dispatch(createBrokerFulfilled(response.data.broker))
      return
    } catch (error) {
      dispatch(createBrokerRejected(error.response?.data?.message))
      return error.response?.data?.message
    }
  }
}

interface UpdateFarmerResponse {
  farmer: Farmer;
}

export const updateFarmer = (data: Farmer): ThunkAction<Promise<{success: boolean; errorMsg?: string}>,
 unknown, unknown, AuthActionTypes> => {
  return async (dispatch: Dispatch) => {
    dispatch(updateFarmerPending())
    try {
      const response = await patch<APIResponse<UpdateFarmerResponse>>(`/farmers/${data.id}`, data)

      if (response.error) throw new Error()
      dispatch(updateFarmerFulfilled(response.data.farmer))
      return { success: true }
    } catch (error) {
      dispatch(updateFarmerRejected(error.response?.data?.message))
      return { success: false, errorMsg: error }
    }
  }
}

interface UpdateBrokerResponse {
  broker: Broker;
}

export const updateBroker = (data: Broker): ThunkAction<Promise<string | void>, unknown, unknown, AuthActionTypes> => {
  return async (dispatch: Dispatch) => {
    dispatch(updateBrokerPending())
    try {
      const response = await patch<APIResponse<UpdateBrokerResponse>>(`/brokers/${data.id}`, data)
      dispatch(updateBrokerFulfilled(response.data.broker))
      return
    } catch (error) {
      dispatch(updateBrokerRejected(error.response?.data?.message))
      return error.response?.data?.message
    }
  }
}

export const deleteBroker = (id: string): ThunkAction<void, unknown, unknown, AuthActionTypes> => {
  return async (dispatch: Dispatch) => {
    dispatch(deleteBrokerPending())
    try {
      await patch<APIResponse<UpdateBrokerResponse>>(`/brokers/${id}/action/delete`)
      dispatch(deleteBrokerFulfilled())
    } catch (error) {
      dispatch(deleteBrokerRejected(error.response?.data?.message))
    }
  }
}

export const suspendBroker = (id: string): ThunkAction<void, unknown, unknown, AuthActionTypes> => {
  return async (dispatch: Dispatch) => {
    dispatch(suspendBrokerPending())
    try {
      const response = await patch<APIResponse<UpdateBrokerResponse>>(`/brokers/${id}/action/suspend`)
      dispatch(suspendBrokerFulfilled(response.data.broker))
    } catch (error) {
      dispatch(suspendBrokerRejected(error.response?.data?.message))
    }
  }
}

interface VerifyAreaCodeResponse {
  isValid: boolean;
}

type VerifyAreaCodeThunkResponse = Promise<{
  success: boolean;
  isValid: boolean;
  errorMsg?: string;
}>

export const verifyAreaCode = (areaCode: number): ThunkAction<
    VerifyAreaCodeThunkResponse,
    unknown,
    unknown,
    AuthActionTypes
  > => {
  return async (dispatch: Dispatch) => {
    dispatch(verifyAreaCodePending())
    try {
      const response = await post<APIResponse<VerifyAreaCodeResponse>>('/area-codes/verify', { areaCode })
      if (response.error) throw new Error()
      dispatch(verifyAreaCodeFulfilled(response.data.isValid))
      return { success: true, isValid: response.data.isValid }
    } catch (error) {
      dispatch(verifyAreaCodeRejected(error))
      return { success: false, isValid: false, errorMsg: error }
    }
  }
}
