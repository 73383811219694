import React from 'react'
import { Modal as RNModal, GestureResponderEvent } from 'react-native'

import Title from '@hedgit/lib/components/typography/title'

import {
  CenteredView,
  ModalView,
  ButtonContainer,
  IconContainer,
  ButtonsContainer,
  Button
} from './styled'

import XIcon from '../../icons/x-icon'
import OkIcon from '../../icons/ok-icon'

interface IButton {
  label: string;
  onPress: (event: GestureResponderEvent) => void;
  testID: string;
  variant: 'primary' | 'secondary';
  isLoading?: boolean;
  isDisabled?: boolean;
}

export interface ModalProps {
  title?: string;
  variant: 'confirm' | 'success' | 'error';
  buttons?: IButton[];
  visible?: boolean;
  onCloseModal?: () => void;
  isLoading?: boolean;
}

const Modal = ({
  title,
  variant,
  buttons,
  visible,
  onCloseModal,
  isLoading
}: ModalProps) => {
  return (
    <RNModal
      animationType='fade'
      visible={visible}
      presentationStyle='overFullScreen'
      statusBarTranslucent={false}
      transparent
      onRequestClose={onCloseModal}
    >
      <CenteredView>
        <ModalView variant={variant}>
          {
              variant !== 'confirm'
                ? (
                  <IconContainer>
                    {variant === 'success' ? <OkIcon /> : null }
                    {variant === 'error' ? <XIcon /> : null }
                  </IconContainer>
                  )
                : null
            }
          <Title>{title}</Title>
          <ButtonsContainer>
            {
                buttons.map((button) => (
                  <ButtonContainer key={button.label}>
                    <Button
                      testID={button.testID}
                      variant={button.variant}
                      onPress={button.onPress}
                      loading={isLoading || button.isLoading}
                      disabled={isLoading || button.isDisabled}
                    >
                      {button.label}
                    </Button>
                  </ButtonContainer>
                )
                )
              }
          </ButtonsContainer>
        </ModalView>
      </CenteredView>
    </RNModal>
  )
}

export default Modal
