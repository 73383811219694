import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconProps } from './types'

const ArrowTwoLeftIcon = ({ color = '#000' }: IconProps) => {
  return (
    <Svg
      width={36}
      height={36}
      viewBox="0 0 36 36"
      fill="none"
    >
      <Path
        d="M20.5 25.5l-7.5-7.5 7.5-7.5"
        stroke={color}
        strokeWidth={2.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default ArrowTwoLeftIcon
